import React, { useState } from 'react'
import useCloseDrawerOnLeave from '../../hooks/useCloseDrawerOnLeave'
import useRefetch from '../../hooks/useRefetch'
import useEvents from '../../hooks/useEvents'
import useSearch from '../../hooks/useSearch'
import IdentifiedVendorsPage from './IdentifiedVendorsPage'
import useIdentifiedVendorsPageQuery from './useIdentifiedVendorsPageQuery'
import useScanRangeSelect from '../alerts/useScanRangeSelect'

const IdentifiedVendorsPageWithState = () => {
    useCloseDrawerOnLeave()
    const [search, handleSearch] = useSearch()
    const [selectedAudit, setSelectedAudit] = useState('ALL')
    const scanRangeState = useScanRangeSelect()
    const { period, startDate, endDate } = scanRangeState
    const {
        audits,
        vendors,
        refetch,
        isFetching,
        isFetchingMore,
        handleFetchMore,
        hasMore,
    } = useIdentifiedVendorsPageQuery(
        search,
        selectedAudit.id === 'ALL' ? null : selectedAudit.id,
        startDate,
        endDate,
        period
    )
    useEvents(['scan:in-progress', 'scan:completed', 'scan:failed'], () =>
        refetch()
    )
    useRefetch(refetch)
    return (
        <IdentifiedVendorsPage
            audits={audits}
            selectedAudit={selectedAudit}
            scanRangeState={scanRangeState}
            startDate={startDate}
            endDate={endDate}
            period={period}
            isFetching={isFetching}
            isFetchingMore={isFetchingMore}
            onFetchMore={handleFetchMore}
            hasMoreRecords={hasMore}
            vendors={vendors}
            search={search}
            onUpdateCompleted={() => refetch()}
            onSearch={handleSearch}
            onChangeAudit={(id) => setSelectedAudit(id)}
        />
    )
}

export default IdentifiedVendorsPageWithState
