import React from 'react'
import gql from 'graphql-tag'
import TableCell from '../../utils/TableCell'
import TableRow from '../../utils/TableRow'
import Tag from '../../utils/Tag'
import useDrawerStack from '../../hooks/useDrawerStack'
import PurposesTags from '../../utils/PurposesTags'
import PopoverVendorInfo from '../../utils/PopoverVendorInfo'
import Avatar from '../../utils/Avatar'
import ScanCookiesPageActionsDropwdownMenu from '../audits-scans-cookies/ScanCookiesPageActionsDropdownMenu'
import IdentifiedCookiesDrawer from '../vendors-cookies/IdentifiedCookieDetailsDrawer'
import AlertCountTag from '../../utils/AlertCountTag'
import ActionButton from '../../utils/ActionButton'
import DateTime from '../../utils/DateTime'
import Tooltip from '../../utils/Tooltip'
import * as constants from '../../../constants'

const { APPLICATION_LEVEL_AUDIT } = constants

const FRAGMENT = gql`
    fragment AuditCookiesPageTableRow on IdentifiedCookie {
        id
        name
        rootDomain
        occurrence
        lastSeenAt
        lastScanCookie {
            id
            type
            domain
            name
            originalName
            value
            retention
            requestUrl
        }
        tracker {
            id
            domain
            purposes {
                id
                name
            }
            vendor {
                ...PopoverVendorInfo
            }
        }
        cookie {
            id
            name
            domain
            regex
            description
            occurrence
            belongsToOrganization
            purposes {
                id
                name
                parent {
                    id
                    name
                    parent {
                        id
                        name
                    }
                }
            }
            description
            vendor {
                ...PopoverVendorInfo
            }
        }
        alerts {
            id
            message
            type
            severityType
        }
    }
`

const AuditCookiesPageTableRow = ({
    audit,
    startDate,
    endDate,
    period,
    cookie: identifiedCookie,
    onClassifyCookieCompleted,
    onDeleteCookieCompleted,
}) => {
    const { open, currentDrawerProps } = useDrawerStack()
    const {
        id,
        name,
        cookie,
        tracker,
        description,
        occurrence,
        rootDomain,
        lastSeenAt,
        alerts = [],
    } = identifiedCookie
    let vendor = null
    let purposes = []
    if (cookie) {
        purposes = cookie.purposes
        vendor = cookie.vendor
    } else if (tracker) {
        purposes = tracker.purposes
        vendor = tracker.vendor
    }
    const { cookies = [] } = audit.cookieTable || {}
    const cookieIds = cookies.filter((c) => c !== null).map((c) => c.id)
    return (
        <TableRow
            isSelectable
            selectionValue={identifiedCookie}
            isActive={currentDrawerProps?.vendorId === id}
            selectionCriteria={(row) => row.id === id}
            data-testid="CookiesPageTableRow"
            onClick={() => {
                open(IdentifiedCookiesDrawer, {
                    identifiedCookieId: id,
                    auditId: audit.id,
                    level: APPLICATION_LEVEL_AUDIT,
                    startDate,
                    endDate,
                    period,
                    onClassifyCookieCompleted,
                    onDeleteCookieCompleted,
                })
            }}
        >
            <TableCell columnId="name" size="sm">
                <div className="flex items-center">
                    <div className="text-sm">
                        <div className="font-medium">{name}</div>
                        <div className="text-gray-500">{rootDomain}</div>
                    </div>
                </div>
            </TableCell>
            <TableCell columnId="alerts" hasText>
                <AlertCountTag alerts={alerts} />
            </TableCell>
            <TableCell columnId="purposes" size="sm">
                <PurposesTags purposes={purposes} />
            </TableCell>
            <TableCell columnId="vendor" size="sm">
                {vendor != null && (
                    <PopoverVendorInfo vendor={vendor}>
                        <Avatar src={vendor.imageUrl} iconName="building-07" />
                    </PopoverVendorInfo>
                )}
            </TableCell>
            <TableCell columnId="occurrence" size="sm">
                <Tag>{occurrence || 'Unknown'}</Tag>
            </TableCell>
            <TableCell hasText columnId="description" size="sm">
                {description && (
                    <Tooltip
                        position="top-start"
                        contentClassName="max-w-lg"
                        content={description}
                    >
                        {description}
                    </Tooltip>
                )}
            </TableCell>
            <TableCell columnId="lastSeenAt" size="sm">
                <DateTime dateOnly>{lastSeenAt}</DateTime>
            </TableCell>
            <TableCell className="text-right">
                <ScanCookiesPageActionsDropwdownMenu
                    cookie={cookie}
                    scanCookie={identifiedCookie.lastScanCookie}
                    onClassifyCookieCompleted={onClassifyCookieCompleted}
                    onDeleteCookieCompleted={onDeleteCookieCompleted}
                >
                    <ActionButton />
                </ScanCookiesPageActionsDropwdownMenu>
            </TableCell>
        </TableRow>
    )
}

AuditCookiesPageTableRow.FRAGMENT = FRAGMENT

export default AuditCookiesPageTableRow
