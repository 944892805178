import React from 'react'
import { Trans } from '@lingui/macro'
import Table from '../../utils/Table'
import AuditCookiesPageTableHeader from './AuditCookiesPageTableHeader'
import AuditCookiesPageTableRow from './AuditCookiesPageTableRow'
import InfiniteScroll from '../../utils/InfiniteScroll'
import TableBody from '../../utils/TableBody'
import EmptyPageCard from '../../utils/EmptyPageCard'

export default function AuditCookiesPageTable({
    audit,
    cookies = [],
    startDate,
    endDate,
    period,
    isFetching,
    isFetchingMore,
    hasMoreRecords,
    onFetchMore,
    onUpdateCompleted,
    onClassifyCookieCompleted,
    onDeleteCookieCompleted,
}) {
    const hasCookies = isFetching || cookies.length > 0
    return (
        <InfiniteScroll
            isFetchingMore={isFetchingMore}
            onFetchMore={onFetchMore}
            hasMoreRecords={hasMoreRecords && !isFetching}
        >
            {hasCookies ? (
                <Table isFetching={isFetching} isFullWidth hasMinWidth={false}>
                    <thead>
                        <AuditCookiesPageTableHeader />
                    </thead>
                    <TableBody>
                        {cookies.map((cookie) => (
                            <AuditCookiesPageTableRow
                                key={cookie.id}
                                audit={audit}
                                cookie={cookie}
                                startDate={startDate}
                                endDate={endDate}
                                period={period}
                                onClassifyCookieCompleted={
                                    onClassifyCookieCompleted
                                }
                                onDeleteCookieCompleted={
                                    onDeleteCookieCompleted
                                }
                            />
                        ))}
                    </TableBody>
                </Table>
            ) : (
                <EmptyPageCard
                    iconName="cookie"
                    title={<Trans>No cookies</Trans>}
                    description={
                        <Trans>
                            No cookies found. Try to expand your filters
                        </Trans>
                    }
                />
            )}
        </InfiniteScroll>
    )
}
