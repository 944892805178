import React from 'react'
import Table from '../../utils/Table'
import TableBody from '../../utils/TableBody'
import InfiniteScroll from '../../utils/InfiniteScroll'
import OrganizationCookiesPageTableRow from './OrganizationCookiesPageTableRow'
import OrganizationCookiesPageTableHeaderRow from './OrganizationCookiesPageTableHeaderRow'

const OrganizationCookiesPageTable = ({
    cookies = [],
    isFetching,
    isFetchingMore,
    hasMoreRecords,
    onFetchMore,
    onDeleteCompleted,
    onUpdateCompleted,
}) => (
    <InfiniteScroll
        isFetchingMore={isFetchingMore}
        onFetchMore={onFetchMore}
        hasMoreRecords={hasMoreRecords}
    >
        <Table
            isFetching={isFetching}
            hasMinWidth={false}
            isFullWidth
            data-testid="CookiesPageTable"
        >
            <thead>
                <OrganizationCookiesPageTableHeaderRow />
            </thead>
            <TableBody>
                {cookies.map((cookie) => (
                    <OrganizationCookiesPageTableRow
                        key={cookie.id}
                        cookie={cookie}
                        onDeleteCompleted={onDeleteCompleted}
                        onUpdateCompleted={onUpdateCompleted}
                    />
                ))}
            </TableBody>
        </Table>
    </InfiniteScroll>
)

export default OrganizationCookiesPageTable
