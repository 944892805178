import React from 'react'
import { cva } from 'cva'
import { twMerge } from 'tailwind-merge'

const itemVariant = cva('flex w-full', {
    variants: {
        isHorizontal: {
            true: 'flex-row',
            false: 'flex-col gap-2',
        },
    },
})

const labelVariant = cva('text-sm font-medium text-slate-900', {
    variants: {
        isHorizontal: {
            true: 'w-[200px] flex-shrink-0',
            false: 'w-full',
        },
    },
})

export default function AlertSummaryListItem({
    className,
    label,
    truncate = true,
    isHorizontal = true,
    children,
}) {
    return (
        <div className={twMerge('', itemVariant({ isHorizontal }), className)}>
            <div
                className={twMerge(
                    labelVariant({ isHorizontal }),
                    truncate ? 'truncate' : ''
                )}
            >
                {label}
            </div>
            <div
                className={twMerge(
                    'w-full max-w-full overflow-hidden break-words text-slate-600'
                )}
            >
                {children}
            </div>
        </div>
    )
}
