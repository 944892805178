import React from 'react'
import gql from 'graphql-tag'
import TableCell from '../../utils/TableCell'
import TableRow from '../../utils/TableRow'
import Tag from '../../utils/Tag'
import useDrawerStack from '../../hooks/useDrawerStack'
import PurposesTags from '../../utils/PurposesTags'
import PopoverVendorInfo from '../../utils/PopoverVendorInfo'
import Avatar from '../../utils/Avatar'
import ScanTrackersPageTableRowActionButtonWithState from '../audits-scans-trackers/ScanTrackersPageActionsDropdownMenu'
import IdentifiedTrackersDrawer from '../vendors-trackers/IdentifiedTrackersDetailsDrawer'
import ActionButton from '../../utils/ActionButton'
import DateTime from '../../utils/DateTime'
import AlertCountTag from '../../utils/AlertCountTag'
import { APPLICATION_LEVEL_AUDIT } from '../../../constants'

const FRAGMENT = gql`
    fragment AuditTrackersPageTableRow on IdentifiedTracker {
        id
        lastSeenAt
        rootDomain
        occurrence
        tracker {
            id
            domain
            belongsToOrganization
            description
            purposes {
                id
                name
                parent {
                    id
                    name
                    parent {
                        id
                        name
                    }
                }
            }
            vendor {
                ...PopoverVendorInfo
            }
        }
        alerts {
            id
            message
            type
            severityType
        }
    }
`

const AuditTrackersPageTableRow = ({
    audit,
    tracker: identifiedTracker,
    startDate,
    endDate,
    period,
    onClassifyTrackerCompleted,
    onDeleteTrackerCompleted,
}) => {
    const { open, currentDrawerProps } = useDrawerStack()
    const {
        id,
        rootDomain,
        tracker,
        occurrence,
        lastSeenAt,
        alerts = [],
    } = identifiedTracker
    return (
        <TableRow
            isSelectable
            selectionValue={identifiedTracker}
            isActive={currentDrawerProps?.vendorId === id}
            selectionCriteria={(row) => row.id === id}
            data-testid="TrackersPageTableRow"
            onClick={() => {
                open(IdentifiedTrackersDrawer, {
                    auditId: audit.id,
                    identifiedTrackerId: id,
                    startDate,
                    endDate,
                    period,
                    level: APPLICATION_LEVEL_AUDIT,
                    onClassifyTrackerCompleted,
                    onDeleteTrackerCompleted,
                })
            }}
        >
            <TableCell columnId="rootdomain" size="sm">
                {rootDomain}
            </TableCell>
            <TableCell columnId="alerts" hasText>
                <AlertCountTag alerts={alerts} />
            </TableCell>
            <TableCell columnId="purposes" size="sm">
                {tracker && <PurposesTags purposes={tracker.purposes} />}
            </TableCell>
            <TableCell columnId="vendor" size="sm">
                {tracker?.vendor != null && (
                    <PopoverVendorInfo vendor={tracker.vendor}>
                        <Avatar
                            src={tracker.vendor.imageUrl}
                            iconName="building-07"
                        />
                    </PopoverVendorInfo>
                )}
            </TableCell>
            <TableCell columnId="occurrence" size="sm">
                <Tag>{occurrence || 'Unknown'}</Tag>
            </TableCell>
            <TableCell hasText columnId="description" size="sm">
                {tracker?.description || ''}
            </TableCell>
            <TableCell columnId="lastSeenAt" size="sm">
                <DateTime dateOnly>{lastSeenAt}</DateTime>
            </TableCell>
            <TableCell className="text-right">
                <ScanTrackersPageTableRowActionButtonWithState
                    tracker={tracker}
                    scanTracker={identifiedTracker}
                    onClassifyTrackerCompleted={onClassifyTrackerCompleted}
                    onDeleteTrackerCompleted={onDeleteTrackerCompleted}
                >
                    <ActionButton />
                </ScanTrackersPageTableRowActionButtonWithState>
            </TableCell>
        </TableRow>
    )
}

AuditTrackersPageTableRow.FRAGMENT = FRAGMENT

export default AuditTrackersPageTableRow
