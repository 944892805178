import React from 'react'
import { Trans } from '@lingui/macro'
import PageContainer from '../../layout/PageContainer'
import AuditSummaryHeader from './AuditAlertSummaryHeader'
import AlertSummaryDataCard from './AlertSummaryDataCard'
import AlertSummaryInfoCard from './AlertSummaryInfoCard'
import AlertSummarySection, {
    AlertSummarySectionTitle,
} from './AlertSummarySection'
import { toTitleCase } from '../../../utils/text'
import LoadingIndicator from '../../utils/LoadingIndicator'
import PurposesTags from '../../utils/PurposesTags'
import AlertSummaryAuditTable from './AlertSummaryAuditTable'
import AuditAlertSummaryCookieDetails from './AuditAlertSummaryCookieDetails'
import AuditAlertSummaryRequestDetails from './AuditAlertSummaryRequestDetails'
import DateTime from '../../utils/DateTime'

export default function OrganizationCookieAlertSummaryReportPage({ alert }) {
    const { lastScanCookie, lastAlertsForAudit, lastCreatedAt } = alert

    const { scanRequest } = lastScanCookie
    const cookie = alert?.cookie || lastScanCookie

    if (!alert) {
        return (
            <PageContainer className="mx-auto max-w-5xl p-8">
                <AuditSummaryHeader />
                <div className="flex h-screen w-full items-center justify-center">
                    <LoadingIndicator />
                </div>
            </PageContainer>
        )
    }

    return (
        <PageContainer className="mx-auto max-w-5xl p-8">
            <AuditSummaryHeader
                description={<DateTime>{lastCreatedAt}</DateTime>}
            />

            {/* Summary */}
            <AlertSummarySection>
                <AlertSummaryInfoCard
                    iconName={alert?.subjectType?.toLowerCase()}
                    type={alert?.severityType?.toLowerCase()}
                    title={toTitleCase(
                        alert?.type?.toLowerCase().split('_').join(' ')
                    )}
                    text={alert?.message}
                />
            </AlertSummarySection>

            {/* Vendor and Subject Section */}
            <AlertSummarySection className="grid grid-cols-2 gap-x-8 gap-y-12">
                <div>
                    <AlertSummarySectionTitle>
                        <Trans>Vendor</Trans>
                    </AlertSummarySectionTitle>
                    <AlertSummaryDataCard
                        title={
                            alert?.vendor?.name || (
                                <Trans>No vendor name found</Trans>
                            )
                        }
                        description="Vendor"
                        avatarSrc={alert?.vendor?.imageUrl}
                        avatarIconName="building-07"
                        text={
                            alert?.vendor?.description ||
                            'No description found for this vendor'
                        }
                    />
                </div>
                <div>
                    <AlertSummarySectionTitle>
                        <Trans>Subject</Trans>
                    </AlertSummarySectionTitle>
                    <AlertSummaryDataCard
                        title={
                            cookie.name || <Trans>No cookie name found</Trans>
                        }
                        description="Cookie"
                        avatarIconName="cookie"
                        afterTitle={
                            <PurposesTags
                                showAll
                                purposes={cookie.purposes || []}
                            />
                        }
                        text={
                            cookie.description ||
                            'No description found for this cookie'
                        }
                    />
                </div>
            </AlertSummarySection>

            {/* All audits: Table */}
            <AlertSummarySection>
                <AlertSummarySectionTitle>
                    <Trans>Audits overview</Trans>
                </AlertSummarySectionTitle>
                <AlertSummaryAuditTable audits={alert?.audits} />
            </AlertSummarySection>

            {/* Domain Section */}
            <AlertSummarySection>
                <AlertSummarySectionTitle>
                    <Trans>Domain</Trans>
                </AlertSummarySectionTitle>
                <AlertSummaryDataCard
                    avatarSrc={lastScanCookie.audit?.imageUrl}
                    avatarIconName="building-07"
                    title={lastScanCookie.audit?.name}
                    description={lastScanCookie.audit?.domains?.map(
                        (domain) => domain?.url
                    )}
                />
            </AlertSummarySection>
            {/* Cookie Instance Example */}
            <AlertSummarySection>
                <AlertSummarySectionTitle>
                    <Trans>Cookie instance example</Trans>
                </AlertSummarySectionTitle>
                <div className="flex flex-col gap-2">
                    {lastScanCookie !== undefined ? (
                        <div className="flex flex-col gap-2">
                            <AuditAlertSummaryCookieDetails
                                scanCookie={lastScanCookie}
                            />
                        </div>
                    ) : (
                        <p>No cookie instance example found</p>
                    )}
                </div>
            </AlertSummarySection>

            <AlertSummarySection>
                <AlertSummarySectionTitle>
                    <Trans>Alert initiator example</Trans>
                </AlertSummarySectionTitle>
                {scanRequest !== undefined ? (
                    <div className="flex flex-col gap-2">
                        <AuditAlertSummaryRequestDetails
                            scanRequest={scanRequest}
                        />
                    </div>
                ) : (
                    <p>No initiator example found</p>
                )}
            </AlertSummarySection>

            <div className="my-16 h-[0.1rem] w-full bg-gray-200" />

            {lastAlertsForAudit.map((auditAlert) => {
                const { audit, scanCookie } = auditAlert
                if (audit.name === lastScanCookie.audit.name) return null // Dirty fix?

                return (
                    <>
                        {/* Domain Section */}
                        <AlertSummarySection>
                            <AlertSummarySectionTitle>
                                <Trans>Domain</Trans>
                            </AlertSummarySectionTitle>
                            <AlertSummaryDataCard
                                avatarSrc={audit?.imageUrl}
                                avatarIconName="building-07"
                                title={audit?.name}
                                description={audit?.domains.map(
                                    (domain) => domain?.url
                                )}
                            />
                        </AlertSummarySection>
                        {/* Cookie Instance Example */}
                        <AlertSummarySection className="mt-8">
                            <AlertSummarySectionTitle>
                                <Trans>Cookie instance example</Trans>
                            </AlertSummarySectionTitle>
                            <div className="flex flex-col gap-2">
                                {scanCookie !== undefined ? (
                                    <div className="flex flex-col gap-2">
                                        <AuditAlertSummaryCookieDetails
                                            scanCookie={scanCookie}
                                        />
                                    </div>
                                ) : (
                                    <p>No cookie instance example found</p>
                                )}
                            </div>
                        </AlertSummarySection>
                    </>
                )
            })}
        </PageContainer>
    )
}
