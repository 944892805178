import useFormState, { createFormValidation } from 'use-form-state'

const validation = createFormValidation([])

const toFormValues = ({
    font,
    rowSize,
    textColor,
    linkColor,
    borderColor,
    selectedColumns = [],
    purposeIds = [],
}) => ({
    font,
    rowSize,
    textColor,
    linkColor,
    borderColor,
    selectedColumns,
    purposeIds,
})

const useCustomizeVendorTableFormState = (params) => {
    const formState = useFormState(toFormValues(params), { validation })
    return formState
}

export default useCustomizeVendorTableFormState
