import React from 'react'
import gql from 'graphql-tag'
import { Trans } from '@lingui/macro'
import { useParams } from 'react-router-dom'
import useAppState from '../../hooks/useAppState'
import useDrawerStack from '../../hooks/useDrawerStack'
import Tag from '../../utils/Tag'
import TableCell from '../../utils/TableCell'
import TableRow from '../../utils/TableRow'
import Tooltip from '../../utils/Tooltip'
import AlertTypeTag from '../../utils/AlertTypeTag'
import * as constants from '../../../constants'
import PopoverVendorInfo from '../../utils/PopoverVendorInfo'
import Avatar from '../../utils/Avatar'
import ActionButton from '../../utils/ActionButton'
import AlertTag from '../../utils/AlertTag'
import AlertsPageDelegateDropdownWithState from '../alerts/AlertsPageDelegateDropdownWithState'
import AlertsPageTableRowActionsWithState from '../alerts/AlertsPageTableRowActionsWithState'
import AlertsPageTableRowActionsStaffWithState from '../alerts/AlertsPageTableRowActionsStaffWithState'
import GroupedAlertDetailsDrawer from '../alerts/GroupedAlertDetailsDrawer'

const { ALERT_STATUS_LEVEL_AUDIT: AUDIT } = constants

const FRAGMENT = gql`
    fragment AuditAlertsPageTableRow on Alert {
        id
        groupingId
        type
        severityType
        message
        payload
        subjectName
        status
        firstCreatedAt
        lastCreatedAt
        audit {
            id
            name
            imageUrl
            maxCookieRetention
        }
        audits {
            id
            name
            imageUrl
        }
        occurrences
        lastScanRequests {
            id
            method
            resourceType
            url
            rootDomain
            search
            postData
            initiator
            headers
            cookies
            status
            initiatorScanRequests {
                id
                url
                tracker {
                    id
                    vendor {
                        ...PopoverVendorInfo
                    }
                }
            }
            downstreamScanRequests {
                id
                url
                tracker {
                    id
                    vendor {
                        ...PopoverVendorInfo
                    }
                }
            }
        }
        lastScanCookies {
            id
            type
            rootDomain
            name
            value
            retention
            expiresAt
            request
            blockedReasons
        }
        scan {
            id
            createdAt
            consentProfile {
                id
                name
                purposes {
                    id
                    name
                }
            }
        }
        vendor {
            ...PopoverVendorInfo
        }
        cookie {
            id
            name
            domain
            belongsToOrganization
            purposes {
                id
                name
            }
            vendor {
                id
                name
            }
        }
        tracker {
            id
            domain
            belongsToOrganization
            purposes {
                id
                name
            }
            vendor {
                id
                name
            }
        }
        comments {
            id
        }
        currentDelegation {
            id
            user {
                fullName
            }
            externalEmail
        }
    }
    ${PopoverVendorInfo.FRAGMENT}
`

const AuditAlertPageTableRow = ({
    alert,
    scanRangeState,
    onAlertStatusChanged,
}) => {
    const { currentUser } = useAppState()
    const { id: auditId } = useParams()
    const { open } = useDrawerStack()
    const {
        id,
        groupingId,
        vendor,
        occurrences,
        subjectName,
        comments = [],
        currentDelegation,
    } = alert
    const hasDelegation =
        currentDelegation !== null &&
        (currentDelegation.user !== null ||
            currentDelegation.externalEmail !== null)
    let delegatedToName = null
    if (hasDelegation && currentDelegation.user !== null) {
        delegatedToName = currentDelegation.user.fullName
    }
    if (hasDelegation && currentDelegation.externalEmail !== null) {
        delegatedToName = currentDelegation.externalEmail
    }
    const { startDate, endDate, selectedPeriod } = scanRangeState
    return (
        <TableRow
            data-testid="AuditAlertsPageTableRow"
            isSelectable
            selectionValue={alert}
            selectionCriteria={(row) => row.id === id}
            onClick={() => {
                open(GroupedAlertDetailsDrawer, {
                    level: AUDIT,
                    auditId,
                    period: selectedPeriod,
                    groupingId,
                    startDate,
                    endDate,
                })
            }}
        >
            <TableCell hasText>
                <div className="flex items-end">
                    <div className="mr-2 text-gray-400">
                        <AlertTypeTag alert={alert} />
                    </div>
                    <div>{subjectName}</div>
                </div>
            </TableCell>
            <TableCell columnId="vendors" size="sm">
                {vendor != null && (
                    <PopoverVendorInfo vendor={vendor}>
                        <Avatar src={vendor.imageUrl} iconName="building-07" />
                    </PopoverVendorInfo>
                )}
            </TableCell>
            <TableCell size="sm">
                <AlertTag alert={alert} />
            </TableCell>
            <TableCell>
                <Tag>{occurrences}</Tag>
            </TableCell>
            <TableCell className="flex items-center justify-end gap-1">
                <Tooltip as="div" content={<Trans>Comment</Trans>}>
                    <ActionButton
                        iconName="chat"
                        iconColor={comments.length > 0 ? 'primary' : 'default'}
                        onClick={() => {
                            open(GroupedAlertDetailsDrawer, {
                                selectedTab: 'activity',
                                level: AUDIT,
                                auditId,
                                groupingId,
                                startDate,
                                endDate,
                                period: selectedPeriod,
                            })
                        }}
                        childrenBefore={
                            comments.length > 0 && (
                                <span className="mr-1 text-primary">
                                    {comments.length}
                                </span>
                            )
                        }
                    />
                </Tooltip>
                <Tooltip
                    as="div"
                    content={
                        hasDelegation ? (
                            <Trans>Delegated to {delegatedToName}</Trans>
                        ) : (
                            <Trans>Delegate</Trans>
                        )
                    }
                >
                    <AlertsPageDelegateDropdownWithState
                        alert={alert}
                        level={AUDIT}
                        onAlertStatusChanged={onAlertStatusChanged}
                    >
                        {hasDelegation ? (
                            <Avatar name={delegatedToName} size="sm" />
                        ) : (
                            <ActionButton iconName="user-assign" />
                        )}
                    </AlertsPageDelegateDropdownWithState>
                </Tooltip>
                {currentUser.isStaff ? (
                    <AlertsPageTableRowActionsStaffWithState
                        level={AUDIT}
                        alert={alert}
                        selectedPeriod={selectedPeriod}
                        onAlertStatusChanged={onAlertStatusChanged}
                    />
                ) : (
                    <AlertsPageTableRowActionsWithState
                        level={AUDIT}
                        alert={alert}
                        selectedPeriod={selectedPeriod}
                        onAlertStatusChanged={onAlertStatusChanged}
                    />
                )}
            </TableCell>
        </TableRow>
    )
}

AuditAlertPageTableRow.FRAGMENT = FRAGMENT

export default AuditAlertPageTableRow
