import React from 'react'
import { Trans } from '@lingui/macro'
import PageContainer from '../../layout/PageContainer'
import AuditSummaryHeader from './AuditAlertSummaryHeader'
import AlertSummaryDataCard from './AlertSummaryDataCard'
import AlertSummaryInfoCard from './AlertSummaryInfoCard'
import AlertSummarySection, {
    AlertSummarySectionTitle,
} from './AlertSummarySection'
import { toTitleCase } from '../../../utils/text'
import PurposesTags from '../../utils/PurposesTags'
import AuditAlertSummaryRequestDetails from './AuditAlertSummaryRequestDetails'
import AlertSummaryAuditTable from './AlertSummaryAuditTable'
import DateTime from '../../utils/DateTime'
import Tag from '../../utils/Tag'

export default function OrganizationTrackerAlertSummaryReportPage({ alert }) {
    const {
        lastScanRequest,
        subjectType,
        subjectName,
        lastAlertsForAudit,
        tracker,
        lastCreatedAt,
        vendor,
    } = alert

    let purposes = []
    if (tracker && Array.isArray(tracker.purposes)) {
        purposes = tracker.purposes.filter((purpose) => purpose !== null)
    }

    return (
        <PageContainer className="mx-auto max-w-5xl p-8">
            <AuditSummaryHeader
                description={<DateTime>{lastCreatedAt}</DateTime>}
            />

            {/* Summary */}
            <AlertSummarySection>
                <AlertSummaryInfoCard
                    iconName={alert?.subjectType?.toLowerCase()}
                    type={alert?.severityType?.toLowerCase()}
                    title={toTitleCase(
                        alert?.type?.toLowerCase().split('_').join(' ')
                    )}
                    text={alert?.message}
                />
            </AlertSummarySection>

            <AlertSummarySection className="grid grid-cols-2 gap-x-8 gap-y-12">
                <div>
                    <AlertSummarySectionTitle>
                        <Trans>Vendor</Trans>
                    </AlertSummarySectionTitle>
                    <AlertSummaryDataCard
                        title={
                            alert?.vendor?.name || (
                                <Trans>No vendor name found</Trans>
                            )
                        }
                        description="Vendor"
                        avatarSrc={vendor?.imageUrl}
                        avatarIconName="building-07"
                        text={
                            alert?.vendor?.description ||
                            'No description found for this vendor'
                        }
                    />
                </div>
                <div>
                    <AlertSummarySectionTitle>
                        <Trans>Subject</Trans>
                    </AlertSummarySectionTitle>
                    <AlertSummaryDataCard
                        title={
                            subjectName || <Trans>No tracker name found</Trans>
                        }
                        description="Tracker"
                        avatarIconName="tracker"
                        afterTitle={
                            purposes ? (
                                <PurposesTags showAll purposes={purposes} />
                            ) : (
                                <Tag>Unclassified</Tag>
                            )
                        }
                    />
                </div>
            </AlertSummarySection>

            <AlertSummarySection>
                <AlertSummarySectionTitle>
                    <Trans>Audits overview</Trans>
                </AlertSummarySectionTitle>
                <AlertSummaryAuditTable audits={alert?.audits} />
            </AlertSummarySection>

            <AlertSummarySection>
                <AlertSummarySectionTitle>
                    <Trans>Domain</Trans>
                </AlertSummarySectionTitle>
                <AlertSummaryDataCard
                    avatarSrc={lastScanRequest.audit?.imageUrl}
                    avatarIconName="building-07"
                    title={lastScanRequest.audit?.name}
                    description={lastScanRequest.audit?.domains.map(
                        (domain) => domain?.url
                    )}
                />
            </AlertSummarySection>

            <AlertSummarySection className="mb-12 border-b border-b-gray-200 pb-12">
                <AlertSummarySectionTitle
                    description={
                        <div className="flex gap-1">
                            <p>Created on:</p>
                            <DateTime>
                                {lastScanRequest.scan.startedAt}
                            </DateTime>
                        </div>
                    }
                >
                    <Trans>Alert initiator example</Trans>
                </AlertSummarySectionTitle>
                {lastScanRequest !== undefined ? (
                    <div className="flex flex-col gap-2">
                        <AuditAlertSummaryRequestDetails
                            scanRequest={lastScanRequest}
                        />
                    </div>
                ) : (
                    <Trans>No initiator example found</Trans>
                )}
            </AlertSummarySection>

            {lastAlertsForAudit.map((auditAlert) => {
                const { audit, scanRequest } = auditAlert
                if (audit.name === lastScanRequest.audit.name) return null // Dirty fix?

                return (
                    <>
                        {/* Domain Section */}
                        <AlertSummarySection className="mt-0">
                            <AlertSummarySectionTitle>
                                <Trans>Domain</Trans>
                            </AlertSummarySectionTitle>
                            <AlertSummaryDataCard
                                avatarSrc={audit?.imageUrl}
                                avatarIconName="building-07"
                                title={audit?.name}
                                description={audit?.domains?.map(
                                    (domain) => domain?.url
                                )}
                            />
                        </AlertSummarySection>
                        {/* Cookie Instance Example */}
                        <AlertSummarySection className="mb-12 border-b border-b-gray-200 pb-12">
                            <AlertSummarySectionTitle>
                                <Trans>Alert Initiator example</Trans>
                            </AlertSummarySectionTitle>
                            {scanRequest !== undefined ? (
                                <div className="flex flex-col gap-2">
                                    <AuditAlertSummaryRequestDetails
                                        scanRequest={scanRequest}
                                    />
                                </div>
                            ) : (
                                <Trans>No initiator example found</Trans>
                            )}
                        </AlertSummarySection>
                    </>
                )
            })}
        </PageContainer>
    )
}
