import React from 'react'
import { twMerge } from 'tailwind-merge'

export default function AlertSummarySection({ children, className }) {
    return <div className={twMerge('mt-12', className)}>{children}</div>
}

export function AlertSummarySectionTitle({ children, description, className }) {
    return (
        <div className={twMerge('mb-4 flex flex-col', className)}>
            <h2 className="text-sm uppercase text-gray-500">{children}</h2>
            {description && (
                <p className="text-sm text-gray-400">{description}</p>
            )}
        </div>
    )
}

export function AlertSummarySectionDescription({ children, className }) {
    return <div className={twMerge('pb-5 pt-3', className)}>{children}</div>
}
