import React from 'react'
import { Trans } from '@lingui/macro'
import Table from '../../utils/Table'
import TableBody from '../../utils/TableBody'
import InfiniteScroll from '../../utils/InfiniteScroll'
import IdentifiedTrackersPageTableHeader from './IdentifiedTrackerPageTableHeader'
import IdentifiedTrackersPageTableRow from './IdentifiedTrackersPageTableRow'
import EmptyPageCard from '../../utils/EmptyPageCard'

export default function IdentifiedTrackersPageTable({
    trackers = [],
    startDate,
    endDate,
    period,
    isFetchingMore,
    isFetching,
    onFetchMore,
    hasMoreRecords,
    onClassifyTrackerCompleted,
    onUpdateTrackerCompleted,
    onDeleteTrackerCompleted,
}) {
    const hasTrackers = isFetching || trackers.length > 0
    return (
        <InfiniteScroll
            isFetchingMore={isFetchingMore}
            onFetchMore={onFetchMore}
            hasMoreRecords={hasMoreRecords}
        >
            {hasTrackers ? (
                <Table isFetching={isFetching} isFullWidth hasMinWidth={false}>
                    <thead>
                        <IdentifiedTrackersPageTableHeader />
                    </thead>
                    <TableBody>
                        {trackers.map((tracker) => (
                            <IdentifiedTrackersPageTableRow
                                key={tracker.id}
                                tracker={tracker}
                                startDate={startDate}
                                endDate={endDate}
                                period={period}
                                onClassifyTrackerCompleted={
                                    onClassifyTrackerCompleted
                                }
                                onUpdateTrackerCompleted={
                                    onUpdateTrackerCompleted
                                }
                                onDeleteTrackerCompleted={
                                    onDeleteTrackerCompleted
                                }
                            />
                        ))}
                    </TableBody>
                </Table>
            ) : (
                <EmptyPageCard
                    iconName="tracker"
                    title={<Trans>No tracker observations</Trans>}
                    description={
                        <Trans>
                            No trackers found. Try to expand your filters
                        </Trans>
                    }
                />
            )}
        </InfiniteScroll>
    )
}
