/* eslint-disable react/no-unstable-nested-components */
import React from 'react'
import { Trans } from '@lingui/macro'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import DrawerHeader from '../../utils/DrawerHeader'
import Tabs, { Tab } from '../../utils/Tabs'
import DrawerLoading from '../../utils/DrawerLoading'
import DrawerError from '../../utils/DrawerError'
import DrawerContent from '../../utils/DrawerContent'
import Tag from '../../utils/Tag'
import AlertCountTag from '../../utils/AlertCountTag'
import ScanPageDetailsRequestDrawerDetailsTab from './ScanRequestDetailsDrawerTabDetails'
import ScanPageDetailsRequestDrawerCookiesTab from './ScanRequestDetailsDrawerTabCookies'
import ScanPageDetailsRequestDrawerAlertsTab from './ScanRequestDetailsDrawerTabAlerts'
import RequestChainItemTable from '../../utils/RequestChainDropdown'

const QUERY = gql`
    query scanRequest($id: ID!) {
        scanRequest(id: $id) {
            id
            rootDomain
            postData
            headers
            url
            resourceType
            cookies
            tracker {
                vendor {
                    name
                    imageUrl
                }
            }
            scanPage {
                url
            }
            alerts {
                id
                subjectName
                severityType
                type
                subjectType
                message
            }
            initiator
            scan {
                startedAt
                domain {
                    url
                }
                consentProfile {
                    name
                }
            }
            session {
                startedAt
                consentProfile {
                    name
                }
            }
            audit {
                name
                imageUrl
            }
            method
            initiatorScanRequests {
                id
                url
            }
            downstreamScanRequests {
                id
                url
            }
        }
    }
`

export default function ScanRequestDetailsDrawer({
    requestId,
    drawerState,
    setDrawerState,
}) {
    const activeTab = drawerState.activeTab || 'details'
    const setActiveTab = (tab) => setDrawerState({ activeTab: tab })
    const variables = { id: requestId }
    const {
        data = {},
        loading,
        error,
    } = useQuery(QUERY, {
        variables,
    })
    if (loading) return <DrawerLoading />
    if (error) return <DrawerError error={error} />

    const {
        rootDomain,
        tracker,
        cookies,
        alerts,
        initiatorScanRequests,
        downstreamScanRequests,
        url,
    } = data.scanRequest
    return (
        <>
            <DrawerHeader
                avatarSrc={tracker ? tracker?.vendor?.imageUrl : ''}
                avatarIconName="code-browser"
                title={rootDomain}
                description="HTTP Request"
                buttons={
                    <RequestChainItemTable
                        initiatorScanRequests={initiatorScanRequests}
                        downstreamScanRequests={downstreamScanRequests}
                        currentUrl={url}
                    />
                }
            />
            <Tabs hasMarginBottom={false} className="px-6">
                <Tab
                    isActive={activeTab === 'details'}
                    onClick={() => setActiveTab('details')}
                >
                    <Trans>Details</Trans>
                </Tab>
                <Tab
                    isActive={activeTab === 'alerts'}
                    onClick={() => setActiveTab('alerts')}
                >
                    <Trans>Alerts</Trans>
                    <AlertCountTag alerts={alerts} />
                </Tab>
                {cookies.length !== 0 && (
                    <Tab
                        isActive={activeTab === 'cookies'}
                        onClick={() => setActiveTab('cookies')}
                    >
                        <Trans>Cookies</Trans>
                        <Tag>{cookies.length}</Tag>
                    </Tab>
                )}
            </Tabs>
            <DrawerContent className="flex flex-col gap-4">
                {activeTab === 'details' && (
                    <ScanPageDetailsRequestDrawerDetailsTab
                        scanRequest={data.scanRequest}
                    />
                )}
                {activeTab === 'cookies' && (
                    <ScanPageDetailsRequestDrawerCookiesTab
                        scanRequestCookies={cookies}
                    />
                )}
                {activeTab === 'alerts' && (
                    <ScanPageDetailsRequestDrawerAlertsTab
                        scanRequestAlerts={alerts}
                    />
                )}
            </DrawerContent>
        </>
    )
}
