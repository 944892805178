import React from 'react'
import { Trans } from '@lingui/macro'
import PageContainer from '../../layout/PageContainer'
import AuditSummaryHeader from './AuditAlertSummaryHeader'
import AlertSummaryDataCard from './AlertSummaryDataCard'
import AlertSummaryInfoCard from './AlertSummaryInfoCard'
import AlertSummarySection, {
    AlertSummarySectionDescription,
    AlertSummarySectionTitle,
} from './AlertSummarySection'
import { toTitleCase } from '../../../utils/text'
import PurposesTags from '../../utils/PurposesTags'
import AuditAlertSummaryRequestDetails from './AuditAlertSummaryRequestDetails'
import AuditAlertSummaryRequestChain from './AuditAlertSummaryRequestChain'
import DateTime from '../../utils/DateTime'
import Tag from '../../utils/Tag'

export default function AuditTrackerAlertSummaryReportPage({ alert }) {
    const {
        lastScanRequest,
        subjectType,
        message,
        subjectName,
        lastCreatedAt,
    } = alert

    const scanRequest = lastScanRequest

    let purposes = []
    if (lastScanRequest && Array.isArray(lastScanRequest.tracker.purposes)) {
        purposes = lastScanRequest.tracker.purposes.filter(
            (purpose) => purpose !== null
        )
    }

    return (
        <PageContainer className="mx-auto max-w-5xl p-8">
            <AuditSummaryHeader
                description={<DateTime>{lastCreatedAt}</DateTime>}
            />

            {/* Summary */}
            <AlertSummarySection>
                <AlertSummaryInfoCard
                    iconName={subjectType?.toLowerCase()}
                    type={alert?.severityType?.toLowerCase()}
                    title={toTitleCase(
                        alert?.type?.toLowerCase().split('_').join(' ')
                    )}
                    text={message}
                />
            </AlertSummarySection>

            {/* Audit Section */}
            <AlertSummarySection>
                <AlertSummarySectionTitle>
                    <Trans>Audit</Trans>
                </AlertSummarySectionTitle>
                <AlertSummaryDataCard
                    avatarSrc={alert.audit?.imageUrl}
                    avatarIconName="building-07"
                    title={alert?.audit?.name}
                    description={alert.audit?.domains.map(
                        (domain) => domain?.url
                    )}
                />
            </AlertSummarySection>

            {/* Vendor and Subject Section */}
            <AlertSummarySection className="grid grid-cols-2 gap-x-8 gap-y-12">
                <div>
                    <AlertSummarySectionTitle>
                        <Trans>Vendor</Trans>
                    </AlertSummarySectionTitle>
                    <AlertSummaryDataCard
                        title={
                            alert?.vendor?.name || (
                                <Trans>No vendor name found</Trans>
                            )
                        }
                        afterTitle={
                            purposes ? (
                                <PurposesTags showAll purposes={purposes} />
                            ) : (
                                <Tag>Unclassified</Tag>
                            )
                        }
                        description="Vendor"
                        avatarSrc={alert?.vendor?.imageUrl}
                        avatarIconName="building-07"
                        text={
                            alert?.vendor?.description || (
                                <Trans>
                                    No description found for this vendor
                                </Trans>
                            )
                        }
                    />
                </div>
                <div>
                    <AlertSummarySectionTitle>
                        <Trans>Subject</Trans>
                    </AlertSummarySectionTitle>
                    <AlertSummaryDataCard
                        title={
                            subjectName || <Trans>No tracker name found</Trans>
                        }
                        description="Tracker"
                        // avatarStatus={subjectType?.toLowerCase() || ''}
                        // avatarSrc={alert?.vendor?.imageUrl}
                        avatarIconName="tracker"
                        afterTitle={
                            purposes ? (
                                <PurposesTags showAll purposes={purposes} />
                            ) : (
                                <Tag>Unclassified</Tag>
                            )
                        }
                        text={
                            alert?.tracker?.description || (
                                <Trans>
                                    No description found for this tracker
                                </Trans>
                            )
                        }
                    />
                </div>
            </AlertSummarySection>
            <AlertSummarySection>
                <AlertSummarySectionTitle>
                    <Trans>Alert instance example</Trans>
                </AlertSummarySectionTitle>
                <AlertSummarySectionDescription>
                    <Trans>
                        This table shows the characteristics of the last request
                        from root domain
                    </Trans>
                    <span className="font-semibold"> {subjectName} </span>
                    <Trans> for the alert.</Trans>
                </AlertSummarySectionDescription>

                {scanRequest !== undefined ? (
                    <div className="flex flex-col gap-2">
                        <AuditAlertSummaryRequestDetails
                            scanRequest={scanRequest}
                        />
                    </div>
                ) : (
                    <p>No initiator example found</p>
                )}
            </AlertSummarySection>

            <AlertSummarySection>
                <AlertSummarySectionTitle
                    description={
                        <div className="flex gap-1">
                            <p>Created on:</p>
                            <DateTime>{scanRequest.scan.startedAt}</DateTime>
                        </div>
                    }
                >
                    <Trans>Alert request initiator chain example</Trans>
                </AlertSummarySectionTitle>
                <AlertSummarySectionDescription>
                    <Trans>
                        This initiator chain includes all requests that
                        initiated this request (above the bold line) and any
                        subsequent requests triggered by this request (below the
                        bold line).
                    </Trans>
                </AlertSummarySectionDescription>
                <AuditAlertSummaryRequestChain
                    initiatorScanRequests={scanRequest?.initiatorScanRequests}
                    currentRequest={{
                        url: scanRequest?.url,
                        imageUrl: scanRequest?.tracker?.vendor?.imageUrl,
                    }}
                    downstreamScanRequests={
                        scanRequest?.downstreamScanRequests || []
                    }
                />
            </AlertSummarySection>
        </PageContainer>
    )
}
