/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState, Fragment } from 'react'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import SelectListOption from './SelectListOption'
import FormSelectList from './FormSelectList'
import { fallbackEmptyArray } from '../../utils/index'
import SelectList from './SelectList'

const QUERY = gql`
    query purposesSelect {
        purposes {
            id
            name
            children {
                id
                name
                children {
                    id
                    name
                }
            }
        }
    }
`

const PurposesSelect = ({ errors = [], value = [], onChange }) => {
    const purposeValue = fallbackEmptyArray(value)
    const [openParent, setOpenParent] = useState(null)
    const [openSubParent, setOpenSubParent] = useState(null)
    const { data = {} } = useQuery(QUERY, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
    })
    const { purposes = [] } = data
    useEffect(() => {
        let newOpenParent = null
        let newOpenSubParent = null
        purposes.forEach((purpose) => {
            purpose.children.forEach((child) => {
                if (child.id === purposeValue) {
                    newOpenParent = purpose.id
                }
                child.children.forEach((subChild) => {
                    if (subChild.id === purposeValue) {
                        newOpenSubParent = child.id
                        newOpenParent = purpose.id
                    }
                })
            })
        })
        setOpenParent(newOpenParent)
        setOpenSubParent(newOpenSubParent)
    }, [purposes, value])
    return (
        <FormSelectList errors={errors}>
            <SelectList>
                {purposes.map(({ id, name, children }) => (
                    <Fragment key={id}>
                        <SelectListOption
                            canExpand
                            key={id}
                            disabled
                            isCheckbox
                            checked={purposeValue.includes(id)}
                            canCollapse={false}
                            isCollapsed={openParent !== id}
                            onClick={() => {
                                if (purposeValue.includes(id)) {
                                    onChange(
                                        purposeValue.filter((v) => v !== id)
                                    )
                                } else {
                                    onChange([...purposeValue, id])
                                }
                            }}
                            onCollapse={() => {
                                if (openParent === id) {
                                    setOpenParent(null)
                                    setOpenSubParent(null)
                                } else {
                                    setOpenParent(id)
                                }
                            }}
                        >
                            {name}
                        </SelectListOption>
                        {openParent === id && (
                            <div className="pl-4">
                                {children.map((child) => (
                                    <Fragment key={child.id}>
                                        <SelectListOption
                                            key={child.id}
                                            disabled
                                            isCheckbox
                                            canCollapse={false}
                                            checked={purposeValue.includes(
                                                child.id
                                            )}
                                            isCollapsed={
                                                openSubParent !== child.id
                                            }
                                            onCollapse={() =>
                                                setOpenSubParent(
                                                    openSubParent === child.id
                                                        ? null
                                                        : child.id
                                                )
                                            }
                                            onClick={() => onChange(child.id)}
                                        >
                                            {child.name}
                                        </SelectListOption>
                                        {openSubParent === child.id && (
                                            <div className="pl-12">
                                                {child.children.map(
                                                    (subChild) => (
                                                        <SelectListOption
                                                            key={subChild.id}
                                                            disabled
                                                            isCheckbox
                                                            checked={purposeValue.includes(
                                                                subChild.id
                                                            )}
                                                            onClick={() =>
                                                                onChange(
                                                                    subChild.id
                                                                )
                                                            }
                                                        >
                                                            {subChild.name}
                                                        </SelectListOption>
                                                    )
                                                )}
                                            </div>
                                        )}
                                    </Fragment>
                                ))}
                            </div>
                        )}
                    </Fragment>
                ))}
            </SelectList>
        </FormSelectList>
    )
}

export default PurposesSelect
