/* eslint-disable no-else-return */
import React from 'react'
import { Trans } from '@lingui/macro'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import Tag from '../../utils/Tag'
import DrawerHeader from '../../utils/DrawerHeader'
import DrawerContent from '../../utils/DrawerContent'
import DrawerLoading from '../../utils/DrawerLoading'
import DrawerError from '../../utils/DrawerError'
import Tabs, { Tab } from '../../utils/Tabs'
import AlertDetailsDrawerTabAlert from './AlertDetailsDrawerTabAlert'
import PurposesTags from '../../utils/PurposesTags'
import ScanCookieDetailsDrawerTabDetails from '../scans/ScanCookieDetailsDrawerTabDetails'
import ScanRequestDetailsDrawerTabDetails from '../scans/ScanRequestDetailsDrawerTabDetails'
import RequestChainItemTable from '../../utils/RequestChainDropdown'

const QUERY = gql`
    query alertById($id: ID!) {
        alertById(id: $id) {
            id
            type
            severityType
            subjectName
            subjectType
            message
            vendor {
                name
                imageUrl
            }
            scanCookie {
                id
                name
                domain
                cookie {
                    vendor {
                        name
                        imageUrl
                    }
                    purposes {
                        name
                    }
                    description
                    domain
                    name
                }
                alerts {
                    id
                    subjectName
                    severityType
                    type
                    subjectType
                    message
                }
                audit {
                    name
                    imageUrl
                }
                scan {
                    startedAt
                    domain {
                        url
                    }
                    consentProfile {
                        name
                    }
                }
                scanPage {
                    url
                }
                tracker {
                    domain
                }
                scanRequest {
                    id
                    url
                    method
                    resourceType
                }
                rootDomain
                type
                retention
                value
            }
            scanRequest {
                id
                rootDomain
                postData
                headers
                url
                resourceType
                cookies
                tracker {
                    purposes {
                        name
                    }
                    vendor {
                        imageUrl
                        name
                    }
                }
                scanPage {
                    url
                }
                alerts {
                    id
                    subjectName
                    severityType
                    type
                    subjectType
                }
                initiator
                cookies
                scan {
                    startedAt
                    domain {
                        url
                    }
                    consentProfile {
                        name
                    }
                }
                audit {
                    name
                    imageUrl
                }
                method
                initiatorScanRequests {
                    id
                    url
                }
                downstreamScanRequests {
                    id
                    url
                }
            }
            session {
                consentProfile {
                    id
                    purposes {
                        id
                        name
                    }
                }
            }
            scan {
                startedAt
                domain {
                    url
                }
                consentProfile {
                    name
                    purposes {
                        id
                        name
                    }
                }
            }
        }
    }
`

function getPurposes(subjectType, scanRequest, scanCookie) {
    if (subjectType.toLowerCase() === 'tracker') {
        return scanRequest?.tracker?.purposes || []
    } else if (subjectType.toLowerCase() === 'cookie') {
        return scanCookie?.cookie?.purposes || []
    } else {
        return []
    }
}

export default function AlertDetailsDrawer({
    alertId,
    page,
    drawerState,
    setDrawerState,
}) {
    const activeTab = drawerState.activeTab || 'alert'
    const setActiveTab = (tab) => setDrawerState({ activeTab: tab })
    const variables = {
        id: alertId,
    }
    const { data = {}, loading, error } = useQuery(QUERY, { variables })

    if (loading) return <DrawerLoading />
    if (error) return <DrawerError error={error} />

    const {
        subjectType,
        subjectName,
        message,
        scanRequest,
        scanCookie,
        vendor,
    } = data.alertById

    const purposes = getPurposes(subjectType, scanRequest, scanCookie)

    return (
        <>
            <DrawerHeader
                avatarIconName="alert-triangle"
                avatarStatus={subjectType?.toLowerCase()}
                avatarSrc={vendor?.imageUrl}
                title={subjectName}
                description={message}
                afterTitle={
                    purposes.length > 0 ? (
                        <PurposesTags showAll purposes={purposes || []} />
                    ) : (
                        <Tag>Unclassified</Tag>
                    )
                }
                buttons={
                    subjectType.toLowerCase() === 'tracker' && (
                        <RequestChainItemTable
                            initiatorScanRequests={
                                scanRequest?.initiatorScanRequests
                            }
                            downstreamScanRequests={
                                scanRequest?.downstreamScanRequests
                            }
                            currentUrl={scanRequest?.url}
                        />
                    )
                }
            />
            <Tabs hasMarginBottom={false} className="px-6">
                <Tab
                    isActive={activeTab === 'alert'}
                    onClick={() => setActiveTab('alert')}
                >
                    <Trans>Alert</Trans>
                </Tab>
                {subjectType.toLowerCase() === 'cookie' &&
                    page !== 'dashboard' && (
                        <Tab
                            isActive={activeTab === 'cookie'}
                            onClick={() => setActiveTab('cookie')}
                        >
                            <Trans>Cookie</Trans>
                        </Tab>
                    )}

                {subjectType.toLowerCase() === 'tracker' &&
                    page !== 'dashboard' && (
                        <Tab
                            isActive={activeTab === 'tracker'}
                            onClick={() => setActiveTab('tracker')}
                        >
                            <Trans>Request</Trans>
                        </Tab>
                    )}
            </Tabs>
            <DrawerContent className="flex flex-col gap-4">
                {activeTab === 'alert' && (
                    <AlertDetailsDrawerTabAlert alert={data.alertById} />
                )}
                {activeTab === 'cookie' && (
                    <ScanCookieDetailsDrawerTabDetails
                        scanCookie={scanCookie}
                    />
                )}
                {activeTab === 'tracker' && (
                    <ScanRequestDetailsDrawerTabDetails
                        scanRequest={scanRequest}
                    />
                )}
            </DrawerContent>
        </>
    )
}
