import React from 'react'
import Avatar from '../../utils/Avatar'

export default function AlertSummaryDataCard({
    title,
    description,
    afterDescription,
    text,
    avatarSrc,
    avatarStatus,
    avatarIconName,
    avatarIconColor,
    avatarColor,
    beforeDescription,
    afterTitle,
    className,
}) {
    return (
        <div className={className}>
            <div className="flex items-center gap-4">
                {avatarIconName && (
                    <Avatar
                        size="md"
                        className="flex-shrink-0"
                        src={avatarSrc}
                        avatarStatus={avatarStatus}
                        iconName={avatarIconName}
                        iconColor={avatarIconColor}
                        color={avatarColor}
                    />
                )}
                <div className="flex flex-col gap-2 pt-1">
                    <div className="flex items-center gap-4">
                        {title && (
                            <h3 className="text-xl font-medium leading-6 text-gray-900">
                                {title}
                            </h3>
                        )}
                        {afterTitle}
                    </div>
                    {description && (
                        <div className="line-clamp-2 w-full items-center gap-2">
                            {beforeDescription}
                            <p className="text-sm leading-5 text-gray-500">
                                {description}
                            </p>
                            {afterDescription}
                        </div>
                    )}
                </div>
            </div>
            <p className="mt-4 text-neutral-500">{text}</p>
        </div>
    )
}
