import React from 'react'
import { useParams, useLocation } from 'react-router-dom'
import AuditCookieAlertSummaryReportPage from './AuditCookieAlertSummaryReportPage'
import AuditTrackerAlertSummaryReportPage from './AuditTrackerAlertSummaryReportPage'
import useScanRangeSelect from '../alerts/useScanRangeSelect'
import LoadingIndicator from '../../utils/LoadingIndicator'
import AuditSummaryHeader from './AuditAlertSummaryHeader'
import PageContainer from '../../layout/PageContainer'
import useAuditAlertSummaryQuery from './useAuditAlertSummaryQuery'
import useOrganizationAlertSummaryQuery from './useOrganizationAlertSummaryQuery'
import OrganizationCookieAlertSummaryReportPage from './OrganizationCookieAlertSummaryReportPage'
import OrganizationTrackerAlertSummaryReportPage from './OrganizationTrackerAlertSummaryReportPage'
import ErrorPage from '../../utils/ErrorPage'

export default function AuditAlertSummaryReportPage() {
    const { id: auditId, groupingId: rawGroupingId } = useParams()
    const { search, hash } = useLocation()
    const queryParams = new URLSearchParams(search)

    let period = queryParams.get('period') || 'LAST_DAY'
    // Bugfix for scenario where a "#" inside the grouped alert name.If not found, check the hash part (fragment)
    let groupingId = rawGroupingId
    if (!queryParams.get('period') && hash) {
        const hashParams = new URLSearchParams(hash.substring(1)) // Remove the leading #
        period = hashParams.get('period') || 'LAST_DAY'
        groupingId = `${rawGroupingId}#`
    }

    const { startDate, endDate } = useScanRangeSelect(period)

    if (auditId) {
        const { alert, error, isFetching, lastScanCookie, lastScanRequest } =
            useAuditAlertSummaryQuery({
                groupingId,
                startDate,
                endDate,
                auditId,
            })
        if (error) {
            return <ErrorPage error={error} showNavigation={false} />
        }
        if (isFetching) {
            return (
                <PageContainer className="mx-auto max-w-5xl p-8">
                    <AuditSummaryHeader />
                    <div className="flex h-screen w-full items-center justify-center">
                        <LoadingIndicator />
                    </div>
                </PageContainer>
            )
        }
        return (
            <>
                {lastScanCookie && (
                    <AuditCookieAlertSummaryReportPage alert={alert} />
                )}
                {lastScanRequest && (
                    <AuditTrackerAlertSummaryReportPage alert={alert} />
                )}
            </>
        )
    }

    if (auditId === undefined) {
        const filters = {
            groupingId,
            startDate,
            endDate,
        }
        const { alert, lastScanCookie, error, lastScanRequest, isFetching } =
            useOrganizationAlertSummaryQuery({
                filters,
            })
        if (error) {
            return <ErrorPage error={error} showNavigation={false} />
        }

        if (isFetching) {
            return (
                <PageContainer className="mx-auto max-w-5xl p-8">
                    <AuditSummaryHeader />
                    <div className="flex h-screen w-full items-center justify-center">
                        <LoadingIndicator />
                    </div>
                </PageContainer>
            )
        }

        return (
            <>
                {lastScanCookie && (
                    <OrganizationCookieAlertSummaryReportPage alert={alert} />
                )}
                {lastScanRequest && (
                    <OrganizationTrackerAlertSummaryReportPage alert={alert} />
                )}
            </>
        )
    }
}
