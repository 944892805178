import React from 'react'
import { Trans } from '@lingui/macro'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import Tag from '../../utils/Tag'
import PurposesTags from '../../utils/PurposesTags'
import AlertCountTag from '../../utils/AlertCountTag'
import DrawerHeader from '../../utils/DrawerHeader'
import Tabs, { Tab } from '../../utils/Tabs'
import DrawerLoading from '../../utils/DrawerLoading'
import DrawerError from '../../utils/DrawerError'
import DrawerContent from '../../utils/DrawerContent'
import ScanPageDetailsCookieDrawerTabDetails from './ScanCookieDetailsDrawerTabDetails'
import ScanPageDetailsCookieDrawerTabAlerts from './ScanCookieDetailsDrawerTabAlerts'
import ScanPageDetailsCookieDrawerTabRequest from './ScanCookieDetailsDrawerTabRequest'

const QUERY = gql`
    query scanCookie($id: ID!) {
        scanCookie(id: $id) {
            id
            name
            cookie {
                purposes {
                    name
                }
                description
                domain
                name
                vendor {
                    name
                    imageUrl
                }
            }
            alerts {
                id
                subjectName
                severityType
                type
                subjectType
                message
            }
            audit {
                name
                imageUrl
            }
            scan {
                startedAt
                domain {
                    url
                }
                consentProfile {
                    name
                }
            }
            session {
                startedAt
                consentProfile {
                    name
                }
            }
            scanPage {
                url
            }
            tracker {
                domain
                vendor {
                    name
                    imageUrl
                }
            }
            scanRequest {
                id
                url
                method
                resourceType
            }
            rootDomain
            type
            retention
            value
        }
    }
`

export default function ScanCookieDetailsDrawer({
    scanCookieId,
    drawerState,
    setDrawerState,
}) {
    const activeTab = drawerState.activeTab || 'details'
    const setActiveTab = (tab) => setDrawerState({ activeTab: tab })
    const variables = { id: scanCookieId }
    const {
        data = {},
        loading,
        error,
    } = useQuery(QUERY, {
        variables,
    })
    if (loading) return <DrawerLoading />
    if (error) return <DrawerError error={error} />

    const { cookie, tracker, name, alerts, scanRequest } = data.scanCookie

    return (
        <>
            <DrawerHeader
                avatarSrc={tracker ? tracker.vendor.imageUrl : ''}
                avatarIconName="cookie"
                title={name}
                afterTitle={
                    cookie ? (
                        <PurposesTags
                            showAll
                            purposes={
                                cookie.purposes && cookie.purposes.length > 0
                                    ? cookie.purposes
                                    : []
                            }
                        />
                    ) : (
                        <Tag>Unclassified</Tag>
                    )
                }
                description={cookie ? cookie.description : 'Cookie'}
            />
            <Tabs hasMarginBottom={false} className="px-6">
                <Tab
                    isActive={activeTab === 'details'}
                    onClick={() => setActiveTab('details')}
                >
                    <Trans>Details</Trans>
                </Tab>
                <Tab
                    isActive={activeTab === 'alerts'}
                    onClick={() => setActiveTab('alerts')}
                >
                    <Trans>Alerts</Trans>
                    <AlertCountTag alerts={alerts} />
                </Tab>
                <Tab
                    isActive={activeTab === 'request'}
                    onClick={() => setActiveTab('request')}
                >
                    <Trans>Request</Trans>
                </Tab>
            </Tabs>
            <DrawerContent className="flex flex-col gap-4">
                {activeTab === 'details' && (
                    <ScanPageDetailsCookieDrawerTabDetails
                        scanCookie={data.scanCookie}
                    />
                )}
                {activeTab === 'alerts' && (
                    <ScanPageDetailsCookieDrawerTabAlerts
                        scanCookieAlerts={alerts}
                    />
                )}
                {activeTab === 'request' && (
                    <ScanPageDetailsCookieDrawerTabRequest
                        scanRequest={scanRequest}
                    />
                )}
            </DrawerContent>
        </>
    )
}
