import React from 'react'
import { Trans } from '@lingui/macro'
import PageContent from '../../utils/PageContent'
import PageContainer from '../../layout/PageContainer'
import OrganizationHeader from '../../layout/OrganizationHeader'
import SidebarContainer from '../../layout/SidebarContainer'
import PageHeader from '../../layout/PageHeader'
import IdentifiedVendorsPageActions from './IdentifiedVendorsPageActions'
import IdentifiedVendorsPageTable from './IdentifiedVendorsPageTable'
import TableStateProvider from '../../providers/TableStateProvider'
import IdentifiedVendorsPageSidebar from './IdentifiedVendorsPageSidebar'

const IdentifiedVendorsPage = ({
    vendors,
    audits,
    search,
    selectedAudit,
    scanRangeState,
    isFetching,
    isFetchingMore,
    hasMoreRecords,
    startDate,
    endDate,
    period,
    onSearch,
    onChangeAudit,
    onFetchMore,
    onUpdateCompleted,
}) => (
    <PageContainer data-testid="IdentifiedVendorsPage">
        <OrganizationHeader />
        <SidebarContainer>
            <IdentifiedVendorsPageSidebar />
            <PageContent size="full">
                <PageHeader
                    title={<Trans>Vendors observations</Trans>}
                    description={
                        <Trans>
                            Below you can find all vendors we observed on your
                            domains.
                        </Trans>
                    }
                />
                <TableStateProvider>
                    <IdentifiedVendorsPageActions
                        audits={audits}
                        search={search}
                        scanRangeState={scanRangeState}
                        selectedAudit={selectedAudit}
                        onChangeAudit={onChangeAudit}
                        onSearch={onSearch}
                    />
                    <IdentifiedVendorsPageTable
                        isFetching={isFetching}
                        vendors={vendors}
                        isFetchingMore={isFetchingMore}
                        hasMoreRecords={hasMoreRecords}
                        onFetchMore={onFetchMore}
                        onUpdateCompleted={onUpdateCompleted}
                        startDate={startDate}
                        endDate={endDate}
                        period={period}
                    />
                </TableStateProvider>
            </PageContent>
        </SidebarContainer>
    </PageContainer>
)

export default IdentifiedVendorsPage
