import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import useFetchMore from '../../hooks/useFetchMore'
import { PAGE_INFO_FRAGMENT } from '../../../apollo/fragments/utils'
import { connectionToCollection } from '../../../utils/graphql'
import AlertsPageTableRow from './AlertsPageTableRow'

const QUERY = gql`
    query alertsPage(
        $after: String
        $offset: Int
        $orderBy: AlertOrderByInput
        $filters: AlertFilters
    ) {
        auditTags
        alerts(
            after: $after
            offset: $offset
            orderBy: $orderBy
            filters: $filters
        ) {
            edges {
                node {
                    ...AlertsPageTableRow
                }
                cursor
            }
            totalCount
            pageInfo {
                ...PageInfo
            }
        }
        audits(first: 100) {
            edges {
                cursor
                node {
                    id
                    name
                }
            }
            pageInfo {
                ...PageInfo
            }
        }
    }
    ${AlertsPageTableRow.FRAGMENT}
    ${PAGE_INFO_FRAGMENT}
`

const useAlertsPageQuery = (
    auditId,
    type,
    tag,
    status,
    severityType,
    startDate,
    endDate
) => {
    const orderBy = { occurrences: 'DESC' }
    const filters = {
        auditId,
        type,
        tags: tag ? [tag] : [],
        status,
        severityType,
        startDate,
        endDate,
    }
    const variables = { orderBy, filters }
    const {
        data = {},
        error,
        loading: isFetching,
        refetch,
        fetchMore,
    } = useQuery(QUERY, {
        variables,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
    })
    const [handleFetchMore, isFetchingMore, hasNextPage] = useFetchMore(
        fetchMore,
        data,
        'alerts'
    )

    return {
        error,
        auditTags: data.auditTags || [],
        alerts: connectionToCollection(data.alerts),
        audits: connectionToCollection(data.audits),
        isFetching,
        isFetchingMore,
        refetch,
        handleFetchMore,
        hasMore: !isFetching && !error && hasNextPage,
    }
}

export default useAlertsPageQuery
