import React from 'react'
import gql from 'graphql-tag'
import TableCell from '../../utils/TableCell'
import TableRow from '../../utils/TableRow'
import Tag from '../../utils/Tag'
import useDrawerStack from '../../hooks/useDrawerStack'
import PurposesTags from '../../utils/PurposesTags'
import PopoverVendorInfo from '../../utils/PopoverVendorInfo'
import Avatar from '../../utils/Avatar'
import AuditAvatarGroup from '../../utils/AuditAvatarGroup'
import ScanCookiesPageTableRowActionButtonWithState from '../audits-scans-cookies/ScanCookiesPageActionsDropdownMenu'
import IdentifiedCookieDetailsDrawer from './IdentifiedCookieDetailsDrawer'
import Tooltip from '../../utils/Tooltip'
import AlertTooltip from '../../utils/AlertTooltip'
import AlertCountTag from '../../utils/AlertCountTag'
import DateTime from '../../utils/DateTime'
import ActionButton from '../../utils/ActionButton'
import * as constants from '../../../constants'

const { APPLICATION_LEVEL_ORGANIZATION } = constants

const FRAGMENT = gql`
    fragment IdentifiedCookiesPageTableRow on IdentifiedCookie {
        id
        name
        rootDomain
        occurrence
        lastSeenAt
        lastScanCookie {
            id
            type
            rootDomain
            name
            originalName
            value
            retention
            requestUrl
        }
        tracker {
            id
            domain
            purposes {
                id
                name
            }
            vendor {
                ...PopoverVendorInfo
            }
        }
        cookie {
            id
            name
            domain
            regex
            description
            belongsToOrganization
            purposes {
                id
                name
                parent {
                    id
                    name
                    parent {
                        id
                        name
                    }
                }
            }
            description
            vendor {
                ...PopoverVendorInfo
            }
        }
        audits {
            id
            name
            imageUrl
        }
        alerts {
            id
            message
            type
            severityType
        }
    }
`

const IdentifiedCookiesPageTableRow = ({
    cookie: identifiedCookie,
    onClassifyCompleted,
    onUpdateCompleted,
    onDeleteCompleted,
    startDate,
    endDate,
    period,
}) => {
    const { open, currentDrawerProps } = useDrawerStack()
    const {
        id,
        name,
        rootDomain,
        cookie,
        tracker,
        lastSeenAt,
        audits,
        alerts = [],
        occurrence,
    } = identifiedCookie
    let vendor = null
    let purposes = []
    if (cookie) {
        purposes = cookie.purposes
        vendor = cookie.vendor
    } else if (tracker) {
        purposes = tracker.purposes
        vendor = tracker.vendor
    }
    return (
        <TableRow
            isSelectable
            selectionValue={identifiedCookie}
            isActive={currentDrawerProps?.vendorId === id}
            selectionCriteria={(row) => row.id === id}
            data-testid="CookiesPageTableRow"
            onClick={() => {
                open(IdentifiedCookieDetailsDrawer, {
                    identifiedCookieId: id,
                    onClassifyCookieCompleted: onClassifyCompleted,
                    onDeleteCookieCompleted: onDeleteCompleted,
                    startDate,
                    endDate,
                    period,
                    level: APPLICATION_LEVEL_ORGANIZATION,
                })
            }}
        >
            <TableCell columnId="nameAndRootdomain" size="sm">
                <div className="flex items-center">
                    <div className="text-sm">
                        <div className="font-medium">{name}</div>
                        <div className="text-gray-500">{rootDomain}</div>
                    </div>
                </div>
            </TableCell>
            <TableCell columnId="purposes" size="sm">
                <PurposesTags purposes={purposes} />
            </TableCell>
            <TableCell columnId="vendors" size="sm">
                {vendor != null && (
                    <PopoverVendorInfo vendor={vendor}>
                        <Avatar src={vendor.imageUrl} iconName="building-07" />
                    </PopoverVendorInfo>
                )}
            </TableCell>
            <TableCell columnId="audits" size="sm">
                <AuditAvatarGroup audits={audits} />
            </TableCell>
            <TableCell columnId="belongsToOrganization">
                {cookie && cookie.belongsToOrganization && (
                    <Tooltip content="This cookies is uniquely classified for your organization">
                        <Tag>Self-classified</Tag>
                    </Tooltip>
                )}
                {!cookie && tracker && (
                    <Tooltip
                        content={
                            <>
                                This cookies is classified by the tracker{' '}
                                <strong>{tracker.domain}</strong>
                            </>
                        }
                    >
                        <Tag>Classified by tracker</Tag>
                    </Tooltip>
                )}
            </TableCell>
            <TableCell columnId="alerts" hasText>
                {alerts.length > 0 && (
                    <AlertTooltip alert={alerts[0]}>
                        <AlertCountTag alerts={alerts} />
                    </AlertTooltip>
                )}
            </TableCell>
            <TableCell columnId="occurrence" size="sm">
                <Tag>{occurrence || 'Unknown'}</Tag>
            </TableCell>
            <TableCell columnId="lastSeenAt" size="sm">
                <DateTime dateOnly>{lastSeenAt}</DateTime>
            </TableCell>
            <TableCell className="text-right">
                <ScanCookiesPageTableRowActionButtonWithState
                    cookie={cookie}
                    scanCookie={identifiedCookie.lastScanCookie}
                    onClassifyCookieCompleted={onClassifyCompleted}
                    onUpdateCookieCompleted={onUpdateCompleted}
                    onDeleteCookieCompleted={onDeleteCompleted}
                >
                    <ActionButton />
                </ScanCookiesPageTableRowActionButtonWithState>
            </TableCell>
        </TableRow>
    )
}

IdentifiedCookiesPageTableRow.FRAGMENT = FRAGMENT

export default IdentifiedCookiesPageTableRow
