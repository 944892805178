import React from 'react'
import { Trans } from '@lingui/macro'
import PageContent from '../../utils/PageContent'
import PageContainer from '../../layout/PageContainer'
import SidebarContainer from '../../layout/SidebarContainer'
import AuditHeader from '../../layout/AuditHeader'
import PageHeader from '../../layout/PageHeader'
import AuditCookiesPageTable from './AuditCookiesPageTable'
import AuditCookiePageActions from './AuditCookiePageActions'
import TableStateProvider from '../../providers/TableStateProvider'
import AuditVendorPageSidebar from '../audits-vendors/AuditVendorPageSidebar'

export default function AuditCookiesPage({
    audit,
    cookies,
    purposes,
    search,
    selectedPurposeId,
    scanRangeState,
    isFetching,
    isFetchingMore,
    hasMoreRecords,
    initialOrderBy,
    onChangeOrderBy,
    onSearch,
    onChangeAudit,
    onChangePurpose,
    onFetchMore,
    onClassifyCookieCompleted,
    onDeleteCookieCompleted,
}) {
    return (
        <PageContainer data-testid="AuditCookiesPage">
            <AuditHeader />
            <SidebarContainer>
                <AuditVendorPageSidebar id={audit.id} />
                <PageContent size="full">
                    <PageHeader
                        title={<Trans>Cookies</Trans>}
                        description={
                            <Trans>
                                Here you can see the cookies that are present in
                                your domain.
                            </Trans>
                        }
                    />
                    <TableStateProvider
                        onChangeOrderBy={onChangeOrderBy}
                        initialOrderBy={initialOrderBy}
                    >
                        <AuditCookiePageActions
                            purposes={purposes}
                            search={search}
                            onSearch={onSearch}
                            scanRangeState={scanRangeState}
                            selectedPurposeId={selectedPurposeId}
                            onChangeAudit={onChangeAudit}
                            onChangePurpose={onChangePurpose}
                        />
                        <AuditCookiesPageTable
                            audit={audit}
                            cookies={cookies}
                            isFetching={isFetching}
                            isFetchingMore={isFetchingMore}
                            hasMoreRecords={hasMoreRecords}
                            startDate={scanRangeState.startDate}
                            endDate={scanRangeState.endDate}
                            period={scanRangeState.period}
                            onFetchMore={onFetchMore}
                            onClassifyCookieCompleted={
                                onClassifyCookieCompleted
                            }
                            onDeleteCookieCompleted={onDeleteCookieCompleted}
                        />
                    </TableStateProvider>
                </PageContent>
            </SidebarContainer>
        </PageContainer>
    )
}
