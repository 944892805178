/* eslint-disable max-len */
import React from 'react'
import { Trans } from '@lingui/macro'
import PageContainer from '../../layout/PageContainer'
import PageContent from '../../utils/PageContent'
import Button from '../../utils/Button'
import Tabs, { TabLink } from '../../utils/Tabs'
import EmptyPageCard from '../../utils/EmptyPageCard'
import PageHeader from '../../layout/PageHeader'
import OrganizationCookiesPageTable from './OrganizationCookiesPageTable'
import OrganizationCookiesPageActions from './OrganizationCookiesPageActions'
import OrganizationVendorsPageHeader from '../classifications-organization-vendors/OrganizationVendorsPageHeader'
import OrganizationHeader from '../../layout/OrganizationHeader'
import SidebarContainer from '../../layout/SidebarContainer'
import IdentifiedVendorsPageSidebar from '../vendors/IdentifiedVendorsPageSidebar'
import TableStateProvider from '../../providers/TableStateProvider'

const OrganizationCookiesPage = ({
    search,
    cookies,
    isFetching,
    isFetchingMore,
    hasMoreRecords,
    onFetchMore,
    onSearch,
    onCreateCookie,
    onUpdateCompleted,
    onDeleteCompleted,
}) => (
    <PageContainer data-testid="OrganizationCookiesPage">
        <OrganizationHeader />
        <SidebarContainer>
            <IdentifiedVendorsPageSidebar />
            <PageContent size="full" isFetching={isFetching}>
                <OrganizationVendorsPageHeader />
                {(() => {
                    if (cookies.length === 0 && search === '') {
                        return (
                            <EmptyPageCard
                                className="mt-8"
                                iconName="cookie"
                                title={<Trans>Known cookies</Trans>}
                                description={
                                    <Trans>
                                        You can define cookies specific to your
                                        organization on top of the Webclew
                                        databases.
                                    </Trans>
                                }
                            />
                        )
                    }
                    return (
                        <TableStateProvider>
                            <OrganizationCookiesPageActions
                                value={search}
                                onSearch={onSearch}
                                onCreateCookie={onCreateCookie}
                            />
                            <OrganizationCookiesPageTable
                                cookies={cookies}
                                isFetching={isFetching}
                                isFetchingMore={isFetchingMore}
                                onFetchMore={onFetchMore}
                                hasMoreRecords={hasMoreRecords}
                                onUpdateCompleted={onUpdateCompleted}
                                onDeleteCompleted={onDeleteCompleted}
                            />
                        </TableStateProvider>
                    )
                })()}
            </PageContent>
        </SidebarContainer>
    </PageContainer>
)

export default OrganizationCookiesPage
