import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'

const QUERY = gql`
    query customizeCookieTableModalPurposesQuery {
        purposes {
            id
            name
        }
    }
`

const useCustomizeCookieTableModalPurposesQuery = (options) => {
    const {
        data = {},
        error,
        loading: isFetching,
        refetch,
    } = useQuery(QUERY, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
        ...options,
    })
    return {
        error,
        purposes: data.purposes || [],
        isFetching,
        refetch,
    }
}

export default useCustomizeCookieTableModalPurposesQuery
