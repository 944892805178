import React from 'react'
import { Trans } from '@lingui/macro'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import Tag from '../../utils/Tag'
import Button from '../../utils/Button'
import DrawerHeader from '../../utils/DrawerHeader'
import DrawerContent from '../../utils/DrawerContent'
import DrawerLoading from '../../utils/DrawerLoading'
import DrawerError from '../../utils/DrawerError'
import PopoverVendorInfo from '../../utils/PopoverVendorInfo'
import Tabs, { Tab } from '../../utils/Tabs'
import IdentifiedCookiesDrawerTabDetails from './IdentifiedCookiesDrawerTabDetails'
import PurposesTags from '../../utils/PurposesTags'
import AlertCountTag from '../../utils/AlertCountTag'
import IdentifiedCookiesDrawerTabObservations from './IdentifiedCookiesDrawerTabObservations'
import IdentifiedCookiesDrawerTabAlerts from './IdentifiedCookiesDrawerTabAlerts'
import ScanCookiesPageActionsDropwdownMenu from '../audits-scans-cookies/ScanCookiesPageActionsDropdownMenu'

const QUERY = gql`
    query IdentifiedCookieDetailsDrawer($id: ID!, $auditIds: [ID]) {
        identifiedCookie(id: $id, auditIds: $auditIds) {
            id
            name
            rootDomain
            lastSeenAt
            lastScanCookie {
                id
                type
                domain
                name
                value
                retention
            }
            cookie {
                id
                name
                description
                domain
                belongsToOrganization
                purposes {
                    id
                    name
                    parent {
                        id
                        name
                        parent {
                            id
                            name
                        }
                    }
                }
                vendor {
                    ...PopoverVendorInfo
                }
            }
            audits {
                id
                type
                name
                isMobile
                isWeb
                scanFrequency
                createdAt
                imageUrl
                tags
                domains {
                    id
                    url
                }
            }
            alerts {
                id
                groupingId
                message
                type
                subjectName
                subjectType
                severityType
                occurrences
                audits {
                    id
                    name
                    imageUrl
                }
            }
            pages {
                id
                url
                auditIds
                scanPages {
                    id
                    scan {
                        id
                        startedAt
                        consentProfile {
                            id
                            name
                            type
                        }
                    }
                }
            }
            sessions {
                id
                audit {
                    id
                }
                startedAt
                consentProfile {
                    id
                    name
                    type
                }
            }
        }
    }
    ${PopoverVendorInfo.FRAGMENT}
`

export default function IdentifiedCookieDetailsDrawer({
    auditId,
    auditIds,
    identifiedCookieId,
    onClassifyCookieCompleted,
    onDeleteCookieCompleted,
    startDate,
    endDate,
    period,
    level,
    drawerState,
    setDrawerState,
}) {
    const activeTab = drawerState.activeTab || 'details'
    const setActiveTab = (tab) => setDrawerState({ activeTab: tab })
    const variables = { id: identifiedCookieId, auditIds }
    const {
        data = {},
        error,
        loading,
    } = useQuery(QUERY, {
        variables,
    })
    if (loading) return <DrawerLoading />
    if (error) return <DrawerError error={error} />
    const { cookie, name, audits, alerts, pages, sessions, lastScanCookie } =
        data.identifiedCookie

    return (
        <>
            <DrawerHeader
                avatarSrc={cookie?.vendor?.imageUrl}
                avatarIconName="cookie"
                title={name}
                afterTitle={
                    <PurposesTags
                        showAll
                        purposes={
                            cookie?.purposes && cookie?.purposes.length > 0
                                ? cookie?.purposes
                                : []
                        }
                    />
                }
                description={cookie?.description}
                buttons={
                    <ScanCookiesPageActionsDropwdownMenu
                        cookie={cookie}
                        scanCookie={lastScanCookie}
                        onClassifyCookieCompleted={onClassifyCookieCompleted}
                        onDeleteCookieCompleted={onDeleteCookieCompleted}
                    >
                        <Button
                            type="white"
                            size="sm"
                            iconBeforeProps={{
                                name: 'menu-dots',
                                size: 'sm',
                            }}
                        />
                    </ScanCookiesPageActionsDropwdownMenu>
                }
            />
            <Tabs hasMarginBottom={false} className="px-6">
                <Tab
                    isActive={activeTab === 'details'}
                    onClick={() => setActiveTab('details')}
                >
                    <Trans>Details</Trans>
                </Tab>
                <Tab
                    isActive={activeTab === 'observations'}
                    onClick={() => setActiveTab('observations')}
                >
                    <Trans>Observations</Trans>
                    <Tag>{pages?.length}</Tag>
                </Tab>
                <Tab
                    isActive={activeTab === 'alerts'}
                    onClick={() => setActiveTab('alerts')}
                >
                    <Trans>Alerts</Trans>
                    <AlertCountTag alerts={alerts} />
                </Tab>
            </Tabs>
            <DrawerContent>
                {activeTab === 'details' && (
                    <IdentifiedCookiesDrawerTabDetails
                        identifiedCookie={data.identifiedCookie}
                    />
                )}
                {activeTab === 'observations' && (
                    <IdentifiedCookiesDrawerTabObservations
                        auditId={auditId}
                        audits={audits}
                        pages={pages}
                        sessions={sessions}
                        cookieName={name}
                    />
                )}
                {activeTab === 'alerts' && (
                    <IdentifiedCookiesDrawerTabAlerts
                        auditId={auditId}
                        alerts={alerts}
                        startDate={startDate}
                        endDate={endDate}
                        period={period}
                        level={level}
                    />
                )}
            </DrawerContent>
        </>
    )
}
