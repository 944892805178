import React, { useState } from 'react'
import useCloseDrawerOnLeave from '../../hooks/useCloseDrawerOnLeave'
import useRefetch from '../../hooks/useRefetch'
import useEvents from '../../hooks/useEvents'
import useSearch from '../../hooks/useSearch'
import AuditVendorsPage from './AuditVendorsPage'
import useAuditVendorsPageQuery from './useAuditVendorsPageQuery'
import useScanRangeSelect from '../alerts/useScanRangeSelect'

const AuditVendorsPageWithState = () => {
    useCloseDrawerOnLeave()
    const [search, handleSearch] = useSearch()
    const dateRangeState = useScanRangeSelect()
    const { period, startDate, endDate } = dateRangeState
    const [orderBy, onChangeOrderBy] = useState({ occurrence: 'DESC' })

    const {
        audit,
        vendors,
        refetch,
        isFetching,
        isFetchingMore,
        handleFetchMore,
        hasMore,
    } = useAuditVendorsPageQuery(search, startDate, endDate, period, orderBy)

    useEvents(['scan:in-progress', 'scan:completed', 'scan:failed'], () =>
        refetch()
    )
    useRefetch(refetch)
    const newVendors = vendors.filter(
        (vendor) =>
            !audit?.vendorTable?.vendors.find(({ id }) => id === vendor.id)
    )
    return (
        <AuditVendorsPage
            audit={audit}
            newVendors={newVendors}
            dateRangeState={dateRangeState}
            isFetching={isFetching}
            isFetchingMore={isFetchingMore}
            onFetchMore={handleFetchMore}
            hasMoreRecords={hasMore}
            vendors={vendors}
            search={search}
            initialOrderBy={orderBy}
            onChangeOrderBy={(value, direction) =>
                onChangeOrderBy({ [value]: direction })
            }
            onUpdateCompleted={() => refetch()}
            onSearch={handleSearch}
            onScanCompleted={() => refetch()}
            startDate={startDate}
            endDate={endDate}
            period={period}
        />
    )
}

export default AuditVendorsPageWithState
