import React from 'react'
import { useRouteMatch, useHistory } from 'react-router-dom'
import useSearch from '../../hooks/useSearch'
import useRefetch from '../../hooks/useRefetch'
import ErrorPage from '../../utils/ErrorPage'
import PageLoading from '../../utils/PageLoading'
import OrganizationCookiesPage from './OrganizationCookiesPage'
import useOrganizationCookiesPageQuery from './useOrganizationCookiesPageQuery'
import CreateOrganizationCookieModalWithState from './CreateOrganizationCookieModalWithState'

const OrganizationCookiesPageWithState = () => {
    const history = useHistory()
    const [search, handleSearch] = useSearch('')
    const createModalIsOpen = useRouteMatch({
        path: '/classifications/organization/cookies/create',
    })
    const {
        cookies,
        error,
        refetch,
        isFetching,
        isFetchingMore,
        handleFetchMore,
        hasMore,
    } = useOrganizationCookiesPageQuery(search)
    useRefetch(refetch)
    return (
        <>
            <OrganizationCookiesPage
                search={search}
                cookies={cookies}
                isFetching={isFetching}
                isFetchingMore={isFetchingMore}
                hasMoreRecords={hasMore}
                onFetchMore={handleFetchMore}
                onSearch={handleSearch}
                onCreateCookie={() =>
                    history.push('/classifications/organization/cookies/create')
                }
                onDeleteCompleted={() => refetch()}
                onUpdateCompleted={() => refetch()}
            />
            {createModalIsOpen && (
                <CreateOrganizationCookieModalWithState
                    onDismiss={() =>
                        history.push('/classifications/organization/cookies')
                    }
                    onCompleted={() => refetch()}
                />
            )}
        </>
    )
}

export default OrganizationCookiesPageWithState
