import React from 'react'
import { Trans } from '@lingui/macro'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import Tag from '../../utils/Tag'
import DateTime from '../../utils/DateTime'
import Button from '../../utils/Button'
import DrawerHeader from '../../utils/DrawerHeader'
import DrawerContent from '../../utils/DrawerContent'
import DrawerLoading from '../../utils/DrawerLoading'
import DrawerError from '../../utils/DrawerError'
import AlertCountTag from '../../utils/AlertCountTag'
import Tabs, { Tab } from '../../utils/Tabs'
import ScanDetailsDrawerTabPages from './ScanDetailsDrawerTabPages'
import ScanDetailsDrawerTabAlerts from './ScanDetailsDrawerTabAlerts'
import ScanDetailsDrawerTabCookies from './ScanDetailsDrawerTabCookies'
import ScanDetailsDrawerTabTrackers from './ScanDetailsDrawerTabTrackers'
import ScanPageActionsDropdownMenu from '../staff-scans/ScanPageActionsDropdownMenu'
import useDrawerStack from '../../hooks/useDrawerStack'
import * as constants from '../../../constants'

const {
    APPLICATION_LEVEL_SCAN: SCAN,
    APPLICATION_LEVEL_AUDIT: AUDIT,
    APPLICATION_LEVEL_ORGANIZATION: ORGANIZATION,
} = constants

const QUERY = gql`
    query scanDetailsDrawer($id: ID!) {
        scan(id: $id) {
            id
            url
            createdAt
            alertsCount
            criticalAlertsCount
            warningAlertsCount
            issueAlertsCount
            consentProfile {
                id
                name
            }
            audit {
                id
                name
                imageUrl
            }
            consentProfile {
                id
                name
                purposes {
                    id
                    name
                }
            }
        }
    }
`

export default function ScanDetailsDrawer({
    scanId,
    onDeleteScanCompleted,
    level,
    drawerState,
    setDrawerState,
}) {
    const { close } = useDrawerStack()
    const activeTab = drawerState.activeTab || 'pages'
    const setActiveTab = (tab) => setDrawerState({ activeTab: tab })
    const variables = { id: scanId }
    const {
        data = {},
        error,
        loading,
    } = useQuery(QUERY, {
        variables,
    })
    if (loading) return <DrawerLoading />
    if (error) return <DrawerError error={error} />
    const {
        audit,
        url,
        consentProfile,
        createdAt,
        criticalAlertsCount,
        warningAlertsCount,
        issueAlertsCount,
    } = data.scan
    return (
        <>
            <DrawerHeader
                avatarSrc={audit.imageUrl}
                avatarIconName="scan"
                title={url}
                afterTitle={
                    <Tag size="md" type="primary">
                        {consentProfile.name}
                    </Tag>
                }
                description={<DateTime>{createdAt}</DateTime>}
                buttons={
                    !level === AUDIT && (
                        <ScanPageActionsDropdownMenu
                            scan={data.scan}
                            onDeleteScanCompleted={() => {
                                close()
                                onDeleteScanCompleted()
                            }}
                        >
                            <Button
                                type="white"
                                size="sm"
                                iconBeforeProps={{
                                    name: 'menu-dots',
                                    size: 'sm',
                                }}
                            />
                        </ScanPageActionsDropdownMenu>
                    )
                }
            />
            <Tabs hasMarginBottom={false} className="px-6">
                <Tab
                    isActive={activeTab === 'pages'}
                    onClick={() => setActiveTab('pages')}
                >
                    <Trans>Pages</Trans>
                </Tab>
                <Tab
                    isActive={activeTab === 'alerts'}
                    onClick={() => setActiveTab('alerts')}
                >
                    <Trans>Alerts</Trans>
                    <AlertCountTag
                        criticalAlertsCount={criticalAlertsCount}
                        issueAlertsCount={issueAlertsCount}
                        warningAlertsCount={warningAlertsCount}
                    />
                </Tab>
                <Tab
                    isActive={activeTab === 'cookies'}
                    onClick={() => setActiveTab('cookies')}
                >
                    <Trans>Cookies</Trans>
                </Tab>
                <Tab
                    isActive={activeTab === 'trackers'}
                    onClick={() => setActiveTab('trackers')}
                >
                    <Trans>Trackers</Trans>
                </Tab>
            </Tabs>
            <DrawerContent>
                {activeTab === 'pages' && (
                    <ScanDetailsDrawerTabPages
                        scanId={scanId}
                        auditId={audit.id}
                    />
                )}
                {activeTab === 'alerts' && (
                    <ScanDetailsDrawerTabAlerts
                        scanId={scanId}
                        auditId={audit.id}
                    />
                )}
                {activeTab === 'cookies' && (
                    <ScanDetailsDrawerTabCookies
                        scanId={scanId}
                        auditId={audit.id}
                    />
                )}
                {activeTab === 'trackers' && (
                    <ScanDetailsDrawerTabTrackers
                        scanId={scanId}
                        auditId={audit.id}
                    />
                )}
            </DrawerContent>
        </>
    )
}
