import React from 'react'
import { Trans } from '@lingui/macro'
import Tag from '../../utils/Tag'
import Tooltip from '../../utils/Tooltip'
import Duration from '../../utils/Duration'
import AlertSummaryListItem from './AlertSummaryDataList'
import DateTime from '../../utils/DateTime'

export default function AuditAlertSummaryCookieDetails({ scanCookie }) {
    const { type, name, value, retention, cookie, scan, scanPage, session } =
        scanCookie
    return (
        <>
            {scan && (
                <AlertSummaryListItem label={<Trans>Scan</Trans>}>
                    <Tag type="primary">
                        <DateTime>{scan.startedAt}</DateTime> -
                        {scan.consentProfile.name}
                    </Tag>
                </AlertSummaryListItem>
            )}
            {session && (
                <AlertSummaryListItem label={<Trans>Session</Trans>}>
                    <Tag type="primary">
                        <DateTime dateOnly>{scan.startedAt}</DateTime> -
                        {scan.consentProfile.name}
                    </Tag>
                </AlertSummaryListItem>
            )}
            {scanPage && (
                <AlertSummaryListItem label={<Trans>Page</Trans>}>
                    {scanPage ? scanPage?.url : 'N/A'}
                </AlertSummaryListItem>
            )}
            {name && (
                <AlertSummaryListItem label={<Trans>Name</Trans>}>
                    {cookie?.name || name}
                </AlertSummaryListItem>
            )}
            {type && (
                <AlertSummaryListItem label={<Trans>Type</Trans>}>
                    <Tag type="primary">{type || 'N/A'}</Tag>
                </AlertSummaryListItem>
            )}

            {cookie?.domain && (
                <AlertSummaryListItem label={<Trans>Domain</Trans>}>
                    {cookie?.domain || ''}
                </AlertSummaryListItem>
            )}

            {retention && (
                <AlertSummaryListItem label={<Trans>Retention</Trans>}>
                    <Duration format={Duration.DAYS}>{retention}</Duration>
                </AlertSummaryListItem>
            )}

            {value && (
                <AlertSummaryListItem label={<Trans>Value</Trans>}>
                    <Tooltip
                        event="hover"
                        position="top-end"
                        content={
                            <div className="max-w-[400px] overflow-hidden break-words">
                                {value}
                            </div>
                        }
                        contentClassName="max-w-[400px]"
                    >
                        {value}
                    </Tooltip>
                </AlertSummaryListItem>
            )}
        </>
    )
}
