import React from 'react'
import { Trans } from '@lingui/macro'
import Table from '../../utils/Table'
import AuditVendorsPageTableHeaderRow from './AuditVendorsPageTableHeaderRow'
import AuditVendorsPageTableRow from './AuditVendorsPageTableRow'
import InfiniteScroll from '../../utils/InfiniteScroll'
import TableBody from '../../utils/TableBody'
import EmptyPageCard from '../../utils/EmptyPageCard'

export default function AuditVendorsPageTable({
    audit,
    vendors = [],
    onUpdateCompleted,
    isFetchingMore,
    onFetchMore,
    hasMoreRecords,
    isFetching,
    startDate,
    endDate,
    period,
}) {
    const hasVendors = isFetching || vendors.length > 0
    return (
        <InfiniteScroll
            isFetchingMore={isFetchingMore}
            onFetchMore={onFetchMore}
            hasMoreRecords={hasMoreRecords && !isFetching}
        >
            {hasVendors ? (
                <Table isFetching={isFetching} isFullWidth hasMinWidth={false}>
                    <thead>
                        <AuditVendorsPageTableHeaderRow />
                    </thead>
                    <TableBody>
                        {vendors.map((vendor) => (
                            <AuditVendorsPageTableRow
                                key={vendor.id}
                                audit={audit}
                                vendor={vendor}
                                onUpdateCompleted={onUpdateCompleted}
                                startDate={startDate}
                                endDate={endDate}
                                period={period}
                            />
                        ))}
                    </TableBody>
                </Table>
            ) : (
                <EmptyPageCard
                    iconName="building-07"
                    title={<Trans>No vendors</Trans>}
                    description={
                        <Trans>
                            No vendors found. Try to expand your filters
                        </Trans>
                    }
                />
            )}
        </InfiniteScroll>
    )
}
