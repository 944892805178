import React from 'react'
import Table from '../../utils/Table'
import TableBody from '../../utils/TableBody'
import InfiniteScroll from '../../utils/InfiniteScroll'
import OrganizationTrackersPageTableRow from './OrganizationTrackersPageTableRow'
import OrganizationTrackersPageTableHeaderRow from './OrganizationTrackersPageTableHeaderRow'

const OrganizationTrackersPageTable = ({
    trackers = [],
    isFetching,
    isFetchingMore,
    hasMoreRecords,
    onFetchMore,
    onUpdateCompleted,
    onDeleteCompleted,
}) => (
    <InfiniteScroll
        isFetchingMore={isFetchingMore}
        onFetchMore={onFetchMore}
        hasMoreRecords={hasMoreRecords}
    >
        <Table
            isFetching={isFetching}
            isFullWidth
            hasMinWidth={false}
            data-testid="TrackersPageTable"
        >
            <thead>
                <OrganizationTrackersPageTableHeaderRow />
            </thead>
            <TableBody>
                {trackers.map((tracker) => (
                    <OrganizationTrackersPageTableRow
                        key={tracker.id}
                        tracker={tracker}
                        onUpdateCompleted={onUpdateCompleted}
                        onDeleteCompleted={onDeleteCompleted}
                    />
                ))}
            </TableBody>
        </Table>
    </InfiniteScroll>
)

export default OrganizationTrackersPageTable
