import React from 'react'
import { Trans } from '@lingui/macro'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import DateTime from '../../utils/DateTime'
import DrawerHeader from '../../utils/DrawerHeader'
import DrawerContent from '../../utils/DrawerContent'
import DrawerLoading from '../../utils/DrawerLoading'
import DrawerError from '../../utils/DrawerError'
import AlertCountTag from '../../utils/AlertCountTag'
import Tabs, { Tab } from '../../utils/Tabs'
import ScanPageDetailsDrawerTabAlerts from '../scans/ScanPageDetailsDrawerTabAlerts '
import { PAGE_INFO_FRAGMENT } from '../../../apollo/fragments/utils'
import AuditSessionDetailsDrawerTabCookies from './AuditSessionDetailsDrawerTabCookies'
import AuditSessionDetailsDrawerTabRequests from './AuditSessionDetailsDrawerTabRequests'

const QUERY = gql`
    query pageRequestsPage($sessionId: ID!, $after: String, $offset: Int) {
        session(id: $sessionId) {
            id
            startedAt
            consentProfile {
                name
            }
            audit {
                id
                name
                imageUrl
            }
            deviceType
        }
        alerts(sessionId: $sessionId, after: $after, offset: $offset) {
            edges {
                node {
                    id
                    scan {
                        id
                    }
                    groupingId
                    type
                    severityType
                    subjectName
                    subjectType
                    message
                    occurrences
                }
                cursor
            }
            totalCount
            pageInfo {
                ...PageInfo
            }
        }
    }
    ${PAGE_INFO_FRAGMENT}
`

export default function AuditSessionsDrawer({
    sessionId,
    drawerState,
    setDrawerState,
}) {
    const TAB_ALERTS = 'alerts'
    const TAB_COOKIES = 'cookies'
    const TAB_REQUESTS = 'requests'
    const activeTab = drawerState.activeTab || 'alerts'
    const setActiveTab = (tab) => setDrawerState({ activeTab: tab })
    const variables = { sessionId }
    const {
        data = {},
        error,
        loading,
        refetch,
    } = useQuery(QUERY, {
        variables,
    })

    if (loading) return <DrawerLoading />
    if (error) return <DrawerError error={error} />

    const { id, deviceType, audit, createdAt } = data.session
    const alerts = data.alerts.edges.map(({ node }) => node)
    return (
        <>
            <DrawerHeader
                avatarSrc={audit.imageUrl}
                avatarIconName="scan"
                title={deviceType}
                description={
                    createdAt ? (
                        <DateTime>{createdAt}</DateTime>
                    ) : (
                        'No creation date found'
                    )
                }
            />
            <Tabs hasMarginBottom={false} className="px-6">
                <Tab
                    isActive={activeTab === TAB_ALERTS}
                    onClick={() => setActiveTab(TAB_ALERTS)}
                >
                    <Trans>Alerts</Trans>
                    <AlertCountTag alerts={alerts} />
                </Tab>
                <Tab
                    isActive={activeTab === TAB_COOKIES}
                    onClick={() => setActiveTab(TAB_COOKIES)}
                >
                    <Trans>Cookies</Trans>
                </Tab>
                <Tab
                    isActive={activeTab === TAB_REQUESTS}
                    onClick={() => setActiveTab(TAB_REQUESTS)}
                >
                    <Trans>Requests</Trans>
                </Tab>
            </Tabs>
            <DrawerContent>
                {activeTab === TAB_ALERTS && (
                    <ScanPageDetailsDrawerTabAlerts
                        level="SESSION"
                        alerts={alerts}
                    />
                )}
                {activeTab === TAB_COOKIES && (
                    <AuditSessionDetailsDrawerTabCookies sessionId={id} />
                )}
                {activeTab === TAB_REQUESTS && (
                    <AuditSessionDetailsDrawerTabRequests
                        audit={audit}
                        sessionId={id}
                    />
                )}
            </DrawerContent>
        </>
    )
}
