import React from 'react'
import { Trans } from '@lingui/macro'
import Table from '../../utils/Table'
import InfiniteScroll from '../../utils/InfiniteScroll'
import TableBody from '../../utils/TableBody'
import IdentifiedCookiesPageTableRow from './IdentifiedCookiesPageTableRow'
import IdentifiedCookiesPageTableHeaderRow from './IdentifiedCookiesPageTableHeaderRow'
import EmptyPageCard from '../../utils/EmptyPageCard'

export default function IdentifiedCookiesPageTable({
    cookies = [],
    startDate,
    endDate,
    period,
    isFetchingMore,
    hasMoreRecords,
    onFetchMore,
    isFetching,
    onClassifyCompleted,
    onUpdateCompleted,
    onDeleteCompleted,
}) {
    const hasCookies = isFetching || cookies.length > 0
    return (
        <InfiniteScroll
            isFetchingMore={isFetchingMore}
            onFetchMore={onFetchMore}
            hasMoreRecords={hasMoreRecords && !isFetching}
        >
            {hasCookies ? (
                <Table isFetching={isFetching} isFullWidth hasMinWidth={false}>
                    <thead>
                        <IdentifiedCookiesPageTableHeaderRow />
                    </thead>
                    <TableBody>
                        {cookies.map((cookie) => (
                            <IdentifiedCookiesPageTableRow
                                key={cookie.id}
                                cookie={cookie}
                                startDate={startDate}
                                endDate={endDate}
                                period={period}
                                onClassifyCompleted={onClassifyCompleted}
                                onUpdateCompleted={onUpdateCompleted}
                                onDeleteCompleted={onDeleteCompleted}
                            />
                        ))}
                    </TableBody>
                </Table>
            ) : (
                <EmptyPageCard
                    iconName="cookie"
                    title={<Trans>No cookie observations</Trans>}
                    description={
                        <Trans>
                            No cookies found. Try to expand your filters
                        </Trans>
                    }
                />
            )}
        </InfiniteScroll>
    )
}
