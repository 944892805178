import React from 'react'
import { Trans } from '@lingui/macro'
import DrawerEmptyCard from '../../utils/DrawerEmptyCard'
import InfoCard from '../../utils/InfoCard'
import { DrawerDetailsItemText } from '../../utils/DrawerDetailsItem'
import Tag from '../../utils/Tag'
import { toTitleCase } from '../../../utils/text'
import ScanRequestDetailsDrawerTabDetails from '../scans/ScanRequestDetailsDrawerTabDetails'
import ScanCookieDetailsDrawerTabDetails from '../scans/ScanCookieDetailsDrawerTabDetails'

export default function GroupedAlertDetailsDrawerTabAlert({ alert }) {
    const {
        vendor,
        message,
        severityType,
        subjectType,
        type,
        scan,
        lastScanCookies,
        lastScanRequests,
        session,
    } = alert

    if (!alert) {
        return (
            <DrawerEmptyCard
                iconName="check-circle"
                title={<Trans>No alerts</Trans>}
                description={
                    <Trans>No alerts were found during this scan. </Trans>
                }
            />
        )
    }
    const lastScanRequest = lastScanRequests.reduce((longest, current) => {
        if (longest === null) {
            return current
        }
        return current.url.length > longest.url.length ? current : longest
    }, null)
    const lastScanCookie = lastScanCookies[0]

    return (
        <>
            <InfoCard
                hasBackgroundcolor
                iconName={subjectType?.toLowerCase()}
                type={severityType?.toLowerCase()}
                title={toTitleCase(type?.toLowerCase().split('_').join(' '))}
                text={message}
            />
            <div className="flex flex-col gap-4">
                <h2 className="pt-4 font-semibold">
                    <Trans>General Information</Trans>
                </h2>
                {vendor?.name && (
                    <DrawerDetailsItemText
                        isHorizontal
                        label={<Trans>Vendor</Trans>}
                    >
                        {vendor?.name}
                    </DrawerDetailsItemText>
                )}
                <div className="flex flex-row gap-1">
                    {scan && (
                        <DrawerDetailsItemText
                            isHorizontal
                            label={<Trans>Allowed Legal Bases</Trans>}
                        >
                            {scan.consentProfile?.purposes.map((purpose) => (
                                <Tag
                                    key={purpose.id}
                                    type="green"
                                    className="mr-2"
                                >
                                    {purpose.name}
                                </Tag>
                            ))}
                        </DrawerDetailsItemText>
                    )}
                    {session && (
                        <>
                            {session.consentProfile?.purposes.map((purpose) => (
                                <DrawerDetailsItemText
                                    isHorizontal
                                    label={<Trans>Allowed Legal Bases</Trans>}
                                >
                                    <Tag key={purpose.id} type="green">
                                        {purpose.name}
                                    </Tag>
                                </DrawerDetailsItemText>
                            ))}
                        </>
                    )}
                </div>

                {scan?.consentProfile?.name && (
                    <DrawerDetailsItemText
                        isHorizontal
                        label={<Trans>Scan consent profile</Trans>}
                    >
                        <Tag>{scan?.consentProfile?.name}</Tag>
                    </DrawerDetailsItemText>
                )}

                <h2 className="pt-4 font-semibold">
                    {`${toTitleCase(subjectType)} details example`}
                </h2>
                {lastScanRequest && (
                    <ScanRequestDetailsDrawerTabDetails
                        scanRequest={lastScanRequest}
                    />
                )}
                {lastScanCookie && (
                    <ScanCookieDetailsDrawerTabDetails
                        scanCookie={lastScanCookie}
                    />
                )}
            </div>
        </>
    )
}
